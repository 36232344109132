import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { SelectMulti } from "stories/forms";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetTags, httpCreateTag, httpUpdateTag, httpDeleteTag } from "services/tags"
 

const PieChartTypeSelect = ({ label, name="pie_chart_type_select",multiple=false,placeholder,disabled, showAll=false, onChange, setFieldValue,validateForm, showAllOption, value, ...rest}) => {
  const { t } = useTranslation();

//   const sectors = useStoreState((state) => state.tags?.tags);
  const sectors = [{id: 1, name: t("pie_chart_type_select_security", "Arvopaperi")}, {id: 2, name: t("pie_chart_type_select_sector", "Toimiala")}, {id: 3, name: t("pie_chart_type_select_category", "Laji")}];


  const getInputOptions = useCallback(() => {

    
    return sectors && sectors.map(obj => {
      console.log("obj", obj)
      return (

        <option key={obj.id} value={obj.id}>{obj.name} </option>
      )
    })
  }, [sectors, value])


  const getSelectedOptions = useCallback(() => {
    if (multiple === false) {
      return [value];
    }
    return value && value.map(obj => {
      return {value: obj.id, label: obj.value}
    })
  }, [value,])

  const getOptions = useCallback(() => {

    return sectors && sectors.map(obj => {
      return {value: obj.id, label: t(`sector_select_${obj.id}`,obj.name), color: obj.color, isDisabled: obj.isDisabled}
    })
  }, [sectors])


  const getSelect = useCallback(() => {

    return(
        <SelectMulti
        isMulti={multiple}
        label={label ? label : t("portfolio_select_label", "Toimiala")}
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        defaultValues={getSelectedOptions()}
        options={getOptions()}
        showAllOption={showAllOption} 
        validateForm={validateForm}
        placeholder={placeholder}
        disabled={disabled}
        focused={true}
        {...rest}
        />
    )

  }, [sectors, value, multiple, showAllOption, validateForm, placeholder, disabled, rest])

  return (
    <>
      {

      getSelect()
      }
     
    </>
  )
  
  
}

export default PieChartTypeSelect;