import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"


const BestWorstPerformance = ({ data }) => {
  const { t } = useTranslation();
  // Sort securities by market_value (assumes data includes a 'market_value' field as percentage)

  //add market value chage to data
  data = data.map((item) => {
    item.market_value_change = item?.market_value + item?.value_total_summary;
    item.market_value_change_percentage =  roundScaleDecimals((item?.market_value + item?.value_total_summary) / (item?.value_total_summary * -1)* 100, 2)
    if (isNaN(item?.market_value_change_percent)) {
      item.market_value_change_percent = roundScaleDecimals(1-1, 2) // 0.00
    }
    if (item?.market_value + item?.value_total_summary == 0){
      item.market_value_change_percent = 0
    }
    return item;
  });

  const sortedData = [...data].sort((a, b) => b?.market_value_change_percentage - a?.market_value_change_percentage);

  // Get top 5 and bottom 5 performers
  const bestPerformers = sortedData.slice(0, 5);
  
  
  const worstPerformers = sortedData.slice(-5).reverse();

  // return(
  //   <div style={{ width: "100%" }}>
  //     dd
  //   </div>
  // )

  return (
    <div style={{ width: "100%" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
        {/* <tr className="bg-light">
            <td colSpan="2"  style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "start" }}>{t("performance_best", "Nousijat")}</td>
          </tr> */}
          <tr className="bg-light">
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "left" }}><Typography variant="small" >{t("performance_winners","Nousijat")} </Typography></th>
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}><Typography variant="small" >{t("performance_performance", "Muutos")} </Typography></th>
          </tr>
        </thead>
        <tbody>


          {bestPerformers.map((item, index) => (
            <tr key={`worst-${index}`}>
            <td className="pl-2" style={{ borderBottom: "1px solid #ddd"}}>
              <Typography variant="small" >
                {item?.related_model?.name}
              </Typography>
            </td>
            <td  className={`${item?.market_value_change_percentage> 0 ? "text-success": "text-danger"} pl-2`} style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}>
            <Typography variant="small" className={`${item?.market_value_change_percentage> 0 ? "text-success": "text-danger"}`}>

              {item?.market_value_change_percentage}%
              </Typography>
            </td>
          </tr>
          ))}

        </tbody>
      </table>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
        {/* <tr className="bg-light mt-5">
            <td colSpan="2"  className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "start" }}>{t("performance_worst", "Laskijat")}</td>
          </tr> */}
          <tr className="bg-light">
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "left" }}><Typography variant="small" >{t("performance_losers","Laskijat")} </Typography></th>
            <th className="px-2 py-1" style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}><Typography variant="small" >{t("performance_performance", "Muutos")} </Typography></th>
          </tr>
        </thead>
        <tbody>
        

          {worstPerformers.map((item, index) => (
            <tr key={`worst-${index}`}>
              <td className="pl-2" style={{ borderBottom: "1px solid #ddd"}}>
                <Typography variant="small" >
                  {item?.related_model?.name}
                </Typography>
              </td>
              <td  className={`${item?.market_value_change_percentage> 0 ? "text-success": "text-danger"} pl-2`} style={{ borderBottom: "1px solid #ddd", textAlign: "right" }}>
              <Typography variant="small" className={`${item?.market_value_change_percentage> 0 ? "text-success": "text-danger"}`}>

                {item?.market_value_change_percentage}%
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

BestWorstPerformance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      market_value: PropTypes.number.isRequired, // Performance as a percentage
    })
  ).isRequired,
};

export default BestWorstPerformance;


// import React from "react";
// import PropTypes from "prop-types";

// const BestWorstPerformance = ({ data }) => {
//   // Sort securities by market_value (assumes data includes a 'market_value' field as percentage)
//   const sortedData = [...data].sort((a, b) => b.market_value - a.market_value);

//   // Get top 5 and bottom 5 performers
//   const bestPerformers = sortedData.slice(0, 5);
//   const worstPerformers = sortedData.slice(-5).reverse();

//   return (
//     <div style={{ display: "flex", gap: "2rem" }}>
//       {/* Best Performers */}
//       <div style={{ flex: 1 }}>
//         <h3>Top 5 Best Performers</h3>
//         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//           <thead>
//             <tr>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Security</th>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>Performance (%)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {bestPerformers.map((item, index) => (
//               <tr key={index}>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>{item?.related_model?.name}</td>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>
//                   {item?.market_value.toFixed(2)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Worst Performers */}
//       <div style={{ flex: 1 }}>
//         <h3>Top 5 Worst Performers</h3>
//         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//           <thead>
//             <tr>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Security</th>
//               <th style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>Performance (%)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {worstPerformers.map((item, index) => (
//               <tr key={index}>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px" }}>{item?.related_model?.name}</td>
//                 <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "right" }}>
//                   {item?.market_value.toFixed(2)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// BestWorstPerformance.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       market_value: PropTypes.number.isRequired, // Performance as a percentage
//     })
//   ).isRequired,
// };

// export default BestWorstPerformance;
