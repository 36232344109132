import React, { useEffect, useRef, useState } from 'react';  
import { httpDownloadFile } from "services/files";  
  
const ImageWithPolygons = ({ fileId, fileName, polygons, fileModel = "File" }) => {  
  const canvasRef = useRef(null);  
  const imageRef = useRef(null);  
  const [fileUrl, setFileUrl] = useState(null);  
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });  
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });  
  
  const downloadFile = () => {  
    if (!fileId || !fileName) {  
      return;  
    }  
  
    const params = { responseType: 'blob' };  
    let fileExtension = fileName.split('.').pop().toLowerCase();  
    let fileTypePre = 'application/octet-stream';  
  
    if (fileExtension === 'png') {  
      fileTypePre = 'image/png';  
    } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {  
      fileTypePre = 'image/jpeg';  
    }  
  
    if (fileModel === "File") {  
      httpDownloadFile(fileId, params)  
        .then((response) => {  
          if (response) {  
            let file = new Blob([response.data], { type: fileTypePre });  
            let fileURL = URL.createObjectURL(file);  
            setFileUrl(fileURL);  
          } else {  
            throw new Error('Network response was not ok.');  
          }  
        })  
        .catch((error) => {  
          console.error('There was an error!', error);  
        });  
    }  
  };  
  
  useEffect(() => {  
    downloadFile();  
  }, [fileId, fileName]);  
  
  useEffect(() => {  
    const image = imageRef.current;  
  
    const handleImageLoad = () => {  
      const { naturalWidth, naturalHeight } = image;  
      setOriginalSize({ width: naturalWidth, height: naturalHeight });  
  
      // Get the displayed size of the image  
      const { width, height } = image.getBoundingClientRect();  
      setImageSize({ width, height });  
    };  
  
    if (image.complete) {  
      handleImageLoad();  
    } else {  
      image.addEventListener('load', handleImageLoad);  
      return () => image.removeEventListener('load', handleImageLoad);  
    }  
  }, [fileUrl]);  
  
  useEffect(() => {  
    const canvas = canvasRef.current;  
    const context = canvas.getContext('2d');  
  
    context.clearRect(0, 0, canvas.width, canvas.height);  
  
    if (!Array.isArray(polygons)) {  
      console.error('Polygons should be an array of arrays');  
      return;  
    }  
  
    // const colors = [  
    //   'rgba(253, 205, 63, 0.8)',  
    //   'rgba(22, 22, 64, 0.8)',  
    //   'rgba(255, 111, 97, 0.8)',  
    //   'rgba(107, 91, 149, 0.8)',  
    //   'rgba(136, 176, 75, 0.8)',  
    //   'rgba(247, 202, 201, 0.8)',  
    //   'rgba(146, 168, 209, 0.8)',  
    //   'rgba(149, 82, 81, 0.8)',  
    //   'rgba(181, 101, 167, 0.8)',  
    //   'rgba(255, 160, 122, 0.8)'  
    // ];  

    const colors = [  
      'rgba(253, 205, 63, 0.8)',   // Yellow  
      'rgba(22, 22, 64, 0.8)',     // Dark Blue  
      'rgba(255, 111, 97, 0.8)',   // Coral  
      'rgba(107, 91, 149, 0.8)',   // Purple  
      'rgba(136, 176, 75, 0.8)',   // Olive Green  
      'rgba(247, 202, 201, 0.8)',  // Light Pink  
      'rgba(146, 168, 209, 0.8)',  // Light Blue  
      'rgba(149, 82, 81, 0.8)',    // Brownish Red  
      'rgba(181, 101, 167, 0.8)',  // Lavender  
      'rgba(255, 160, 122, 0.8)',  // Light Salmon  
      'rgba(72, 201, 176, 0.8)',   // Mint Green  
      'rgba(255, 195, 0, 0.8)'     // Bright Yellow  
  ];  
    
    polygons.forEach((polygon, index) => {  
      if (!Array.isArray(polygon)) {  
        console.error('Each polygon should be an array of points');  
        return;  
      }  
    
      context.beginPath();  
      polygon.forEach((point, pointIndex) => {  
        if (!Array.isArray(point) || point.length !== 2) {  
          console.error('Each point should be an array with two numbers');  
          return;  
        }  
    
        // Scale the absolute coordinates to the current image size  
        const [x, y] = point;  
        const scaledX = (x / originalSize.width) * imageSize.width;  
        const scaledY = (y / originalSize.height) * imageSize.height;  
        context.lineTo(scaledX, scaledY);  

    
        // if (pointIndex === 0) {  
        //   context.moveTo(scaledX, scaledY);  
        // } else {  
        //   context.lineTo(scaledX, scaledY);  
        // }  
      });  
      context.closePath();  
      context.strokeStyle = colors[index % colors.length];  
      context.lineWidth = 2;  
      context.stroke();  
    });  
  }, [polygons, imageSize, originalSize]);  
    
  return (  
    <div style={{ position: 'relative' }}>
      {/* {JSON.stringify(polygons)} */}
      <img  
        src={fileUrl}  
        alt="Document"  
        style={{ display: 'block', width: '100%' }}  
        ref={imageRef}  
      />  
      <canvas  
        ref={canvasRef}  
        style={{ position: 'absolute', top: 0, left: 0 }}  
        width={imageSize.width}  
        height={imageSize.height}  
      />  
    </div>  
  );  
  };  
    
  export default ImageWithPolygons;  


// import React, { useEffect, useRef } from 'react';  

// import {
//     httpDownloadFile
//   } from "services/files"
  
// const ImageWithPolygons = ({fileId, fileName, fileURL1, polygons, fileModel="File" }) => {  
//   const canvasRef = useRef(null);  
//   const imageRef = useRef(null);  
//   const [fileUrl, setFileUrl] = React.useState(null);
//   console.log("fileId", fileId, fileName, polygons)
//   const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });  

//   const downloadFile = () => {
//       if (!fileId || fileId == "" || fileName == "", !fileName) {
//         return
//       }
//       // openModal()
//       // if (docs && docs.length > 0) 
//       // {
//       // return;
//       // }
//       // // Assuming `fileExtension` contains the file extension (e.g., "xlsx", "png", etc.)

//       // // let a = document.createElement("a");
//       // // document.body.appendChild(a);

//       // // a.style = "display: none";
//       const params = {
//       responseType: 'blob',
//       };

//       let fileExtension = fileName.split('.').pop().toLowerCase();
//       let fileTypePre = 'application/octet-stream'; // default MIME type
//       console.log("fileExtension", fileExtension)

//       // Determine the MIME type based on the file extension
//       if (fileExtension === 'xlsx') {
//       fileTypePre = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
//       } else if (fileExtension === 'pdf') {
//       fileTypePre = 'application/pdf';
//       }
//       else if (fileExtension === 'png') {
//       fileTypePre = 'image/png';
//       } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
//       fileTypePre = 'image/jpeg';
//       } // Add more conditions for other file types as needed
//       //ms word file
//       else if (fileExtension === 'docx') {
//       fileTypePre = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
//       // fileTypePre = 'image/gif';
//       }
//       else if (fileExtension === 'txt') {
//       fileTypePre = 'text/plain';
//       }
//       if (fileModel == "File"){
      
//       httpDownloadFile(fileId, params)
//       .then((response) => {
//           // Check if the response is successful
//           if (response) {
//           let file = new Blob([response.data], {type: fileTypePre});
//           let fileURL = URL.createObjectURL(file);
//           setFileUrl(fileURL);
//           // setDocs([{uri:fileURL}])
//           // setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: " "}]);



//           // setDocs([{ uri: fileURL, fileName: fileName}]);


//           // Create a FileReader to convert Blob to Data URL
//           //  let reader = new FileReader();
//           //  reader.onloadend = () => {
//           //      let fileURL = reader.result;
//           //      setFileUrl(fileURL);
//           //     //  let fileURL = `data:${fileType};base64,${reader.result.split(',')[1]}`;
//           //     //  setFileUrl(fileURL);
//           //     //  setDocs([{ uri: fileURL, fileType: fileType }]);
//           //      setDocs([{ uri: fileURL, fileType: fileTypePre, fileName: fileName}]);
//           //     //  setDocs([{ uri: fileURL}]);
//           //  };
//           //  reader.readAsDataURL(file);
//           // a.href = fileURL;

//           }
//           // Handle error response
//           else{

//           throw new Error('Network response was not ok.');
//           }
//       })
//       .catch((error) => {
//           // Handle any errors
//           console.error('There was an error!', error);
//       });
//       }
//   }

//   useEffect(() => {
//       downloadFile();
//   }, [fileId, fileName]);

  

//   useEffect(() => {  
//     const canvas = canvasRef.current;  
//     const context = canvas.getContext('2d'); // 
  
//     // Clear the canvas  
//     context.clearRect(0, 0, canvas.width, canvas.height);  
  
//     // Check if polygons is an array  
//     if (!Array.isArray(polygons)) {  
//       console.error('Polygons should be an array of arrays');  
//       return;  
//     }  
//     // Define an array of colors  
//     const colors = [  
//         'rgba(253, 205, 63, 0.8)',
//         'rgba(22, 22, 64, 0.8)',
//         'rgba(255, 111, 97, 0.8)',
//         'rgba(107, 91, 149, 0.8)',
//         'rgba(136, 176, 75, 0.8)',
//         'rgba(247, 202, 201, 0.8)',
//         'rgba(146, 168, 209, 0.8)',
//         'rgba(149, 82, 81, 0.8)',
//         'rgba(181, 101, 167, 0.8)',
//         'rgba(255, 160, 122, 0.8)'
//     ];  


  
//     // Draw each polygon  
//     polygons.forEach((polygon, index) => {  
//       // Check if each polygon is an array  
//       if (!Array.isArray(polygon)) {  
//         console.error('Each polygon should be an array of points');  
//         return;  
//       }  
  
//       context.beginPath();  
//       polygon.forEach(point => {  
//         // Check if each point is an array with two numbers  
//         if (!Array.isArray(point) || point.length !== 2) {  
//           console.error('Each point should be an array with two numbers');  
//           return;  
//         }  
  
//         const [x, y] = point;  
//         context.lineTo(x, y);  
//       });  
//       context.closePath();  
//     //   context.strokeStyle = 'red';  
//       context.strokeStyle = colors[index % colors.length];  
//       context.lineWidth = 2;  
//       context.stroke();  
//     });  
//   }, [polygons, fileId, fileName, fileUrl]);  

//   useEffect(() => {  
//     const image = imageRef.current;  
  
//     const handleImageLoad = () => {  
//       // Get the natural dimensions of the image  
//       const { naturalWidth, naturalHeight } = image;  
//       setImageSize({ width: naturalWidth, height: naturalHeight });  
//     };  
  
//     if (image.complete) {  
//       // If the image is already loaded, set the size immediately  
//       handleImageLoad();  
//     } else {  
//       // Otherwise, set the size once the image loads  
//       image.addEventListener('load', handleImageLoad);  
//       return () => image.removeEventListener('load', handleImageLoad);  
//     }  
//   }, [fileUrl]);

//   // if (!fileUrl) {  
//   //   return <div>Loading...</div>;  
//   // }

//   return (  
//     <div style={{ position: 'relative' }}>  
//     {JSON.stringify(imageSize)}
//       <img src={fileUrl} alt="Document" style={{ display: 'block', width:"100%" }}   ref={imageRef}   />  
//       <canvas  
//         ref={canvasRef}  
//         style={{ position: 'absolute', top: 0, left: 0 }}  
//         // width={600} // Adjust to match your image size  
//         // height={800} // Adjust to match your image size  
//         width={imageSize.width}  
//         height={imageSize.height}  
//       />  
//     </div>  

//     );
// }

// export default ImageWithPolygons;