import http from './api'


// STOCK-TRANSACTIONS
export const httpGetTransactions = (params) => {
  console.log("httpGetTransactions");
  return http({
    url: `/transactions/transactions/` ,
    method: 'GET',
    params,
  })
}
export const httpGetTransaction = (id) => {
  console.log("httpGetTransaction");
  return http({
    url: `/transactions/transactions/${id}/` ,
    method: 'GET',
  })
}
export const httpCreateTransaction = (data) => {
  console.log("httpCreateTransaction");
  return http({
    url: `/transactions/transactions/` ,
    method: 'POST',
    data,
  })
}
export const httpUpdateTransaction = (id, data) => {
  console.log("httpUpdateTransaction");
  return http({
    url: `/transactions/transactions/${id}/` ,
    method: 'PATCH',
    data,
  })
}
export const httpDeleteTransaction = (id) => {
  console.log("httpDeleteTransaction");
  return http({
    url: `/transactions/transactions/${id}/` ,
    method: 'DELETE',
  })
}

export const httpGetStockDateBalance = (params) => {
  console.log("httpGetStockDateBalance");
  return http({
    url: `/transactions/transactions/get_stock_date_balance/` ,
    method: 'GET',
    params,
  })
}



export const httpGetDiarySummary = (params) => {
  console.log("httpGetStockDateBalance");
  return http({
    url: `/transactions/transactions/diary_summary/` ,
    method: 'GET',
    params,
  })
}

export const httpGetDiarySummaryExcel = (params) => {
  console.log("httpGetDiarySummaryExcel");
  return http({
    url: `/transactions/transactions/diary_summary/` ,
    method: 'GET',
    responseType: 'arraybuffer',
    params
  })
}

export const httpGetIncomeReportSummary = (params) => {
  console.log("httpGetStockDateBalance");
  return http({
    url: `/transactions/transactions/profit_report_summary/` ,
    method: 'GET',
    params,
  })
}

export const httpGetAllPortfoliosSummary = (params) => {
  console.log("httpGetAllPortfoliosSummary");
  return http({
    url: `/transactions/transactions/portfolio_profit_summary/` ,
    method: 'GET',
    params,
  })
}


export const httpGetCurrentBatchBalanceRatio = (params) => {
  console.log("httpGetCurrentBatchBalanceRatio");
  return http({
    url: `/transactions/get_current_batch_balance_ratio/` ,
    method: 'GET',
    params,
  })
}

export const httpGetTransactionsExcel = (params) => {
  console.log("httpGetTransactionsExcel");
  return http({
    url: `/transactions/transactions/export_excel/` ,
    method: 'GET',
    responseType: 'arraybuffer',
    params
  })
}

export const httpGetIncomeReportExcel = (params,data) => {
  console.log("httpGetIncomeReportExcel");
  return http({
    url: `/transactions/transactions/profit_report_summary/` ,
    method: 'POST',
    responseType: 'arraybuffer',
    params,
    data
  })
}




export const httpUploadTransactionsExcel = (data) => {
  console.log("httpUploadTransactionsExcel");
  return http({
    url: `/transactions/excel_import_upload/` ,
    method: 'POST',
    data
  })
}

export const httpUploadTransactionsExcelImport = (data) => {
  console.log("httpUploadTransactionsExcel");
  return http({
    url: `/transactions/upload_excel/` ,
    method: 'POST',
    data
  })
}

export const httpUploadTransactionsExcelImportOld = (data) => {
  console.log("httpUploadTransactionsExcel");
  return http({
    url: `/transactions/upload_excel_old/` ,
    method: 'POST',
    data
  })
}


export const httpGetBatchesGtZero = (params) => {
  console.log("httpGetBatchesGtZero");
  return http({
    url: `/transactions/transactions/get_batches_gt_zero/` ,
    method: 'GET',
    params,
  })
}

export const httpGetAllSecuritiesAllTransactions = (params) => {
  console.log("httpGetStockDateBalance");
  return http({
    url: `/transactions/transactions/all_securities_all_transactions/` ,
    method: 'GET',
    params,
  })
}


// PRIVATE STOCK-TRANSACTIONS

export const httpGetPrivateStockTransactions = (params) => {
  console.log("httpGetPrivateStockTransactions");
  return http({
    url: `/transactions/private_stock_transactions/` ,
    method: 'GET',
    params,
  })
}
export const httpGetPrivateStockTransaction = (id) => {
  console.log("httpGetPrivateStockTransaction");
  return http({
    url: `/transactions/private_stock_transactions/${id}/` ,
    method: 'GET',
  })
}
export const httpCreatePrivateStockTransaction = (data) => {
  console.log("httpCreatePrivateStockTransaction");
  return http({
    url: `/transactions/private_stock_transactions/` ,
    method: 'POST',
    data,
  })
}
export const httpUpdatePrivateStockTransaction = (id, data) => {
  console.log("httpUpdatePrivateStockTransaction");
  return http({
    url: `/transactions/private_stock_transactions/${id}/` ,
    method: 'PATCH',
    data,
  })
}
export const httpDeletePrivateStockTransaction = (id) => {
  console.log("httpDeletePrivateStockTransaction");
  return http({
    url: `/transactions/private_stock_transactions/${id}/` ,
    method: 'DELETE',
  })
}

export const httpGetPrivateStockDateBalance = (params) => {
  console.log("httpGetPrivateStockDateBalance");
  return http({
    url: `/transactions/private_stock_transactions/get_stock_date_balance/` ,
    method: 'GET',
    params,
  })
}

export const httpArrayCreateSplitTransaction = (data) => {
  console.log("httpCreatePrivateStockTransaction");
  return http({
    url: `/transactions/transactions/array_create_split_transactions/` ,
    method: 'POST',
    data,
  })
}

export const httpArrayCreateDividendTransaction = (data) => {
  console.log("httpCreatePrivateStockTransaction");
  return http({
    url: `/transactions/transactions/array_create_dividend_transactions/` ,
    method: 'POST',
    data,
  })
}

export const httpArrayCreateExchangeTransaction = (data) => {
  console.log("httpCreatePrivateStockTransaction");
  return http({
    url: `/transactions/transactions/array_create_exchange_transactions/` ,
    method: 'POST',
    data,
  })
}


export const httpGetTransactionsCount = (params) => {
  console.log("httpGetTransactions");
  return http({
    url: `/transactions/transactions/portfolio_transactions_count/` ,
    method: 'GET',
    params,
  })
}


export const httpGetPreTransactions = (params) => {
  console.log("httpGetPreTransactions");
  return http({
    url: `/transactions/pre_transactions/` ,
    method: 'GET',
    params,
  })
}

export const httpUpdatePreTransaction = ( data) => {
  console.log("httpUpdatePreTransaction");
  return http({
    url: `/transactions/update_pre_transactions/` ,
    method: 'PATCH',
    data,
  })
}