import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { round,roundScaleDecimals, thousandFormat } from "services/helpers"




import { useAuthContext } from "contexts/AuthContext"


// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic, Popover } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import PageTabs from "components/PageTabs/PageTabs"
import {  Pagination } from "stories/tables";

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect" 


// App
import PrivateInvestmentFundEdit from "views/app/PrivateInvestmentFundEdit/PrivateInvestmentFundEdit"
import PrivateInvestmentFundSelect from "components/Forms/PrivateInvestmentFundSelect/PrivateInvestmentFundSelect"
import { httpGetPrivateInvestimentFundDatas } from "services/private_investment_funds"
import { httpGetSplits } from "services/splits"
import { httpGetDividends } from "services/dividends"
import { getDate } from "services/datetime"
import PrivateInvestmentFundRateEdit from "views/app/PrivateInvestmentFundRateEdit/PrivateInvestmentFundRateEdit"
import PrivateInvestmentFundRatesChart from "views/app/PrivateInvestmentFundRatesChart/PrivateInvestmentFundRatesChart"
import PrivateInvestmentFundSplitsList from "views/app/PrivateInvestmentFundSplitsList/PrivateInvestmentFundSplitsList"
import PrivateInvestmentFundDividendsList from "views/app/PrivateInvestmentFundDividendsList/PrivateInvestmentFundDividendsList"
import Technicalanalysis from "views/app/Technicalanalysis/Technicalanalysis"
import { getStickyHeaderDates } from "@fullcalendar/core";



const PrivateInvestmentFundRatesList = ({match, history}, props) => {
  
  const { t } = useTranslation();
  const location = useLocation();
  const getPrivateInvestmentFunds = useStoreActions((actions) => actions.privateInvestmentFunds.getObjects);
  const privateInvestmentFunds = useStoreState((state) => state.privateInvestmentFunds?.objects);

  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  const [splits, setSplits] = useState([])
  const [addSplit, setAddSplit] = useState()
  const [dividends, setDividends] = useState([])
  const [addDividend, setAddDividend] = useState()
  const [locationState, setLocationState] = useState()

  const { accountUserType } = useAuthContext();
  const account = useStoreState((state) => state.account?.account);




  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-date",
    type: locationState?.path,
  })

  //create deap copy of location.state
  useEffect(() => {
    setLocationState({...location?.state})
  }, [])


  useEffect(() => {
    if (location.state?.fundId){
      setFilters(s => ({
        ...s,
        private_investment_fund: location?.state?.fundId || props?.fundId,
        private_investment_fund_name: location?.state?.fundName || props?.fundName,
        currency: location?.state?.currency,
        symbol: location?.state?.symbol,
        stock_market: location?.state?.stock_market
      }))
      // console.log(location?.state)
      // setFieldValue("private_investment_fund", location?.state?.fundId )
      // setFieldValue("private_investment_fund_name", location?.state?.fundName )
      //set private investment fund 
    }
    else if (privateInvestmentFunds?.[0]?.id > 0 && !filters?.private_investment_fund) {
      setFilters(s => ({
        ...s,
        private_investment_fund: privateInvestmentFunds?.[0]?.id,
        private_investment_fund_name: privateInvestmentFunds?.[0]?.name,
      }))
    }
  }, [privateInvestmentFunds])

  
  const handleChange = useCallback( ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
  }, [])
  
  const getRates = () => {
    let params = {
      ...filters,
    }
    if (!filters?.private_investment_fund || filters?.private_investment_fund == "") {
      return;
    }
    
    httpGetPrivateInvestimentFundDatas(params).then(res => {
      setObjects(res?.data?.results)
      setCount(res?.data?.count)
    })
  }

  const getSplits = () => {
    let params = {
      ...filters,
      ordering: "-date_effective",
      related_model_type: 30,
      related_model_id: filters?.private_investment_fund
    }
    if(params?.related_model_id != null && params?.related_model_id != "" ) {

      httpGetSplits(params).then(res => {
        setSplits(res?.data?.results)
        console.log(res?.data)
      })
    }
  }

  const getDividends = () => {
    let params = {
      ...filters,
      ordering: "-ex_date",
      related_model_type: 30,
      related_model_id: filters?.private_investment_fund
    }
    if(params?.related_model_id != null && params?.related_model_id != "" ) {

      httpGetDividends(params).then(res => {
        setDividends(res?.data?.results)
        console.log(res?.data)
      })
    }
  }

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("Rahastot"), url: "/dashboard/"},

      {name: t("PrivateInvestmentFundsList_title", "Ulkopuoliset rahastot"), url: "/dashboard/private_investment_funds"},

      // {name: t("routes_dashboard_investment_fund_rates", "Kurssit"), url: "/dashboard/private_investment_funds_rates", optional: true},
      {name: filters.private_investment_fund_name, url: "/dashboard/private_investment_funds", optional: true},

    ]

    //if location state include crumbLinks return them
    if (locationState && locationState?.crumbLinks) {
      const crumbLinks = [...locationState.crumbLinks]
      // crumbLinks.push({name: t("routes_dashboard_investment_fund_rates", "Kurssit"), url: "/dashboard/private_investment_funds_rates", optional: true})
      crumbLinks.push({name: filters.private_investment_fund_name, url: "/dashboard/private_investment_funds", optional: true},)
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.pif) {
      setFilters(s => ({
        ...s,
        private_investment_fund: { value: parsed?.pif },
        private_investment_fund_name: "Hmm"
      }))
    }
  }, [location])
  
  useEffect(() => {
    getPrivateInvestmentFunds({
      limit: 1000,
      offset:0,
      type: location?.state?.path,
    })
  }, [])
  
  useEffect(() => {
    getRates()
    getSplits()
    getDividends()
  }, [filters])
  
  
  const headers = [
    { label: t("PrivateInvestmentFundRatesList_name", "Arvopaperi"), key: "name"},
    { label: t("PrivateInvestmentFundRatesList_stock_date", "Päiväys"), key: "date"},
    { label: t("PrivateInvestmentFundRatesList_ending_price", "Päätös hinta"), key: "updated_price"},
    { label: t("PrivateInvestmentFundRatesList_actions", " "), key: "actions"},

  ];

  const get_headers = useCallback(() => {

    // account?.show_relation_to_security && headers.splice(1, 0, { label: t("PrivateInvestmentFundRatesList_stock", "Kirjanpidoss"), key: "accounting_price_boolean"})

    const modifiedHeaders = [...headers]; // Create a copy of the headers array

    if (account?.show_relation_to_security) {
      modifiedHeaders.splice(2, 0, { label: t("PrivateInvestmentFundRatesList_accounting_date", "Kirjanpito-päiväys"), key: "accounting_price_date" });
    }
  
    return modifiedHeaders;
  }, [account])


  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const name = filters?.private_investment_fund_name;
    return objects && objects.map((row, index) => {
      return {
        ...row,
        name: name,
        date: getDate(row?.date),
        accounting_price_date: getDate(row?.accounting_price_date),
        // updated_price: twoDigits(row?.updated_price),
        updated_price: row?.updated_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_price, 8)),
          className: "text-right"
        },
        // accounting_price_boolean: true && (  >),
        actions: (
          <div className="d-flex justify-content-end">
            {/* {account?.show_relation_to_security && row?.accounting_price_boolean && (
              <>
                <i id={`connectedt-${row?.id}`} className="fas fa-archive text-primary mr-auto my-auto"></i>
                <Popover
                placement="left"
                target={`connectedt-${row?.id}`}
                > 
                <>
                <Typography className="text-dark mb-1" variant="medium">
                {t('Kirjanpitoarvo ')}
                {row?.accounting_price_date ? moment(row?.accounting_price_date).format('DD.MM.YYYY') : ""}
                </Typography>  
                </>

                </Popover>
            </>
              )} */}
            <Button className="btn-sm" color="secondary" disabled={accountUserType==="read_only"} onClick={() => openModalEdit(row?.id)}>
              {t("Muokkaa")}
            </Button>
          </div >
        ),
      }
    })
  }, [objects])
  
  const setFieldValue = (name, value) => {
    
    if (typeof value === 'object') {
      setFilters(s => ({
        ...s,
        [name]: value?.id > 0 ? value?.id : undefined,
        offset: 0,
      }))
    }
    else {
      setFilters(s => ({
        ...s,
        [name]: value,
        offset: 0,
      }))
    }
  }
  
  const resolvePrivateInvestmentSelect = (params) => {
    if (params?.name) {
      setFieldValue("private_investment_fund_name", params?.name)
    }

  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );

  const tabs = [
    {
      key: "rate_list",
      label: t("rate_list", "Kurssit"),
      
    },
    {
      key: "splits",
      label: t("splits", "Splitit"),
    },
    {
      key: "dividends",
      label: t("dividends", "Osingot"),
    },    {
      key: "rate_chart",
      label: t("rate_chart", "Tekninen analyysi"),
      
    },

    ]

    useEffect(() => {
      if  (String(location?.pathname) === match.path){
  
        history.push(generatePath(match.path + "/:tab", {
          tab: tabs[0]?.key
        }))
      }
    }, [match])
  
  // Modal: New/Edit
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getRates()
    getSplits()
    getDividends()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

    // Modal: New/Edit
  
    const [modalEditFundOpen, setModalEditFundOpen] = useState(false)
    const [modalEditFundId, setModalEditFundId] = useState()
    
    const toggleModalEditFund = () => {
      setModalEditFundOpen(s => !s);
    }
    
    const openModalEditFund = (id) => {
      setModalEditFundOpen(true)
      setModalEditFundId(id);
    }
    
    const modalEditFundResolve = () => {
      setModalEditFundOpen(false);
      setModalEditFundId(null);
      // getAllSecurities()
      // getAllSecuritiesOptions()
      // getPrivateInvestmentFunds(filters)
    }
    
    const modalEditFundReject = () => {
      setModalEditFundOpen(false);
      setModalEditFundId(null);
    }

  const listView = (props) => {
    return(
      <>

       
        <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={get_headers()}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
        />

        <Modal
          title={t("private_investment_fund_rate_edit_title","Omat kurssikirjaukset")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundRateEdit
          privateInvestmentFund={filters?.private_investment_fund}
          privateInvestmentFundName={filters?.private_investment_fund_name}
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
          splits= {splits}
          // dividends = {dividends}
        />
        </Modal>
      </>
    )
  }

  const SplitListView = (props) => {
    return(
      <>
      
       
        <TableAuto
        showRowNumber={true}
        showId={true}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
        />

        <Modal
          title={t("Private investment fund")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
        <PrivateInvestmentFundRateEdit
          privateInvestmentFund={filters?.private_investment_fund}
          privateInvestmentFundName={filters?.private_investment_fund_name}
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
          splits= {splits}
        />
        </Modal>
      </>
    )
  }
  

  const getDefaultValues = useCallback(() => {
    if (location?.state?.fundId) {
      return {value: location.state?.fundId, label: location.state?.fundName}
      return {value: location.state?.fundId, label: location.state?.fundName}
    }

    return {value: filters?.private_investment_fund, label: filters?.private_investment_fund_name }
  }, [filters, privateInvestmentFunds])

  const twoDigits = (number) => {
    number = Number(number)
    return number && number.toFixed(2)
  }


  const getChartHeader = useCallback(() => {
    //sort objects by date
    const sortedObjects = objects?.sort((a, b) => {
      return new Date(a?.date) - new Date(b?.date);
    })
    return (
      <Typography bold className="p-2">
        {twoDigits(sortedObjects?.[sortedObjects.length -1]?.updated_price)} {sortedObjects?.[sortedObjects.length -1]?.currency_label}
      </Typography>
    )
  }, [objects])


  
  return (
    <>
      {/* <PageHeader title={t("PrivateInvestmentFundRates_title", "Kurssit")} /> */}
      <div className="d-flex justify-content-start mb-2">
        <PageHeader title={filters?.private_investment_fund_name} />
        {accountUserType!=="read_only" && ( 
          <Button size="sm" variant="secondary" className="ml-2"   onClick={() => openModalEditFund(filters?.private_investment_fund)}><i className="fas fa-edit"></i></Button>
        )  
        }
      </div>
        
      {/* {JSON.stringify(location?.state?.crumbLinks)}
      {JSON.stringify(locationState)} */}
       {/* {JSON.stringify(locationState)} */}
      <BreadcrumbsDynamic links={getCrumbLinks()} />
      <div className="d-flex">
        <PageTabs match={match} location={location} tabs={tabs} />
        {accountUserType!=="read_only" && (

        
        <div className="ml-auto">
          {location.pathname.includes("rate_list") ? (
            <Button size="normal" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundRatesList_create_price","Uusi kirjaus")}</Button>
            ):(
              <>
              {/* getChartHeader()*/}
              </>
              )
            }
            {location.pathname.includes("splits") ? (
              addSplit
              ):(
                <>
                </>
                )
              }
            {location.pathname.includes("dividends") ? (
              addDividend
              ):(
                <>
                </>
                )
            }
        </div>
        )}
      </div>
      <PageContainer>
      <div className="d-flex flex-row align-items-center mb-3">
      {/* <div className="p-2 pr-1">
        <h4 className="mb-0">{t("PrivateInvestmentFundRatesList_private_investment_fund", "Rahasto")}</h4>
        
      </div> */}
      {/* <div className="p-2">
        <PrivateInvestmentFundSelect 
          {...getFormikInputProps("private_investment_fund")}
          value={getDefaultValues()}
          setFieldValue={setFieldValue}
          handleResolve={resolvePrivateInvestmentSelect}
          defaultValues={ getDefaultValues() }
          setFilters={setFilters}
          ratesView={true}
        />
      </div> */}
      {  !loading && (
          <div className="d-flex align-items-center ml-auto">
            {location.pathname.includes("rate_list") && (
              <>
              <div className="mx-2" variant="h4">{t("PrivateInvestmentFundsList_count", "Näytä")}</div>
              <Pagination 
              paginationOffset={filters?.offset}
              paginationLimit={filters?.limit}
              paginationCount={count}
              setFilters={setFilters}
              showPaginationCurrentPage={false}
              showPaginationNextAndPrevious={false}
              showVerticalBorders={false}
              disabled={false}
              />
              <div className="mx-2" variant="h4">{t("riviä")}</div>
              <div className="ml-4">
              <CurrencySelect 
                label={""}
                disabled={true}
                value={filters?.currency?filters?.currency?.content:""}
                noDefault={true}
              />
              </div>  
              </>

              
              
            )}
          </div>
      )}
      <div className="ml-2">
      {location.pathname.includes("rate_list") ? (
        <>
        {/* <Button size="normal" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundRatesList_create_price","Uusi kirjaus")}</Button>*/}
        </>
      ):(
        <>
       {/* {location.pathname.includes("rate_chart")?getChartHeader():null} */}
        </>
      )
      }
      </div>
    </div>
      
      <Switch>
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "rate_list"
            })
          }
          render={props => listView(props)}

        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "rate_chart"
            })
          }
          // render={props => <PrivateInvestmentFundRatesChart {...props} objects={objects} />}
          render={props => <Technicalanalysis  data={filters}/>}

        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "splits"
            })
          }
          render={props =>  <PrivateInvestmentFundSplitsList  {...props} getRates={getRates} setObjectsToChart={setObjects} filters={filters} getSplitsParent={getSplits} setFilters={setFilters} setAddSplit={setAddSplit} />}

        />
        <Route
          path={
            generatePath(match.path + "/:tab", {
              tab: "dividends"
            })
          }
          render={props =>  <PrivateInvestmentFundDividendsList  {...props} getRates={getRates} related_model_type={30} setObjectsToChart={setObjects} filters={filters} getSplitsParent={getDividends} setFilters={setFilters} setAddSplit={setAddDividend} />}
        />
      </Switch>
    </PageContainer>

    <Modal
          title={t("PrivateInvestmentFundEdit_title", "Oma arvopaperi")}
          isOpen={modalEditFundOpen}
          toggleModal={toggleModalEditFund}
        >
        <PrivateInvestmentFundEdit
          objectId={modalEditFundId}
          handleResolve={modalEditFundResolve}
          handleReject={modalEditFundReject}
        />
      </Modal>

    </>
  );
}

export default PrivateInvestmentFundRatesList;


// <div className="d-flex flex-row align-items-end">
// <div className="p-2 pr-1">
//   <h4>{t("PrivateInvestmentFundRatesList_private_investment_fund", "Rahasto")}</h4>
  
// </div>

// <div className="p-2">
//   <PrivateInvestmentFundSelect 
//     {...getFormikInputProps("private_investment_fund")}
//     setFieldValue={setFieldValue}
//     handleResolve={resolvePrivateInvestmentSelect}
//     defaultValues={ {value: location.state?.fundId, label: location.state?.fundName}}
//   />
// </div>
// <div className="pb-2 ml-auto">
//   <Button size="normal" color="secondary" onClick={() => openModalEdit(null)}>{t("PrivateInvestmentFundRatesList_create_price","Uusi kirjaus")}</Button>
// </div>
// </div>

// <TableAuto
// showRowNumber={true}
// showId={true}
// checkboxes={false}
// headers={headers}
// headerColor={"light"}
// rows={getTableRows()}
// loading={loading}
// pagination={true}
// paginationPrevious={paginationPrevious}
// paginationNext={paginationNext}
// paginationOffset={filters?.offset}
// paginationLimit={filters?.limit}
// paginationCount={count}
// setFilters={setFilters}
// />

// <Modal
//   title={t("Private investment fund")}
//   isOpen={modalEditOpen}
//   toggleModal={toggleModalEdit}
// >
// <PrivateInvestmentFundRateEdit
//   privateInvestmentFund={filters?.private_investment_fund}
//   privateInvestmentFundName={filters?.private_investment_fund_name}
//   objectId={modalEditId}
//   handleResolve={modalEditResolve}
//   handleReject={modalEditReject}
// />
// </Modal>