import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { httpGetPortfolioSummaryTable} from "services/portfolios"
import { set } from 'date-fns';
import { useAuthContext } from "contexts/AuthContext"
import { is } from 'date-fns/locale';



// Register required chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MarketValueChart = ({ portfolio,portfolio_list, related_model_id=null, related_model_type=null,sectors=null, date__gte, date__lte=null,week_day, setChartData,rows=null, type="percentage_change", currency=null, aspectRatio=null }) => {
  const { myUser, getMyUser, loadingMyUser, accountUserType } = useAuthContext()
  const userLanguage = myUser.language || 'default';

  const [data, setData] = useState([]);
  const [chartDataLocal, setChartDataLocal] = useState();
  const [chartOptionsLocal, setChartOptionsLocal] = useState();

  const [isSingleMonth, setIsSingleMonth] = useState(false);
  const [isSingleWeek, setIsSingleWeek] = useState(false);


  useEffect(() => {
    let securities = [];
    if (rows !== null) {
      for (let i = 0; i < rows.length; i++) {
        securities.push({related_model_id: rows[i].related_model_id, related_model_type: rows[i].related_model_type});
      }
    }

    const params = {
      portfolio: portfolio? portfolio: portfolio_list,
      related_model_id: related_model_id,
      sectors: sectors,
      related_model_type: related_model_type && related_model_type?.filter(item => item.id !== "all").map(item => item.id),
      date__gte: date__gte,
      date__lte: date__lte,
      week_day: week_day,
      currency: currency,

    };
    // if (securities.length > 0) {
    //   params["securities"] = securities;
    // }
    httpGetPortfolioSummaryTable(params).then((response) => {


      setData(response?.data?.chart_data);


      setChartData(response?.data?.sub_data);

        // Determine if data includes only one month
      const data = response?.data?.chart_data;
      if (data.length > 0) {

        // const isSingleMonth = data.length > 0 && new Date(data[0].date).getMonth() === new Date(data[data.length - 1].date).getMonth();
        const isSingleMonth = data.length > 0 && (new Date(data[data.length - 1].date) - new Date(data[0].date)) / (1000 * 60 * 60 * 24) <= 27;
        
        // Determine if data includes only one week
        const firstDate = new Date(data[0].date);
        const lastDate = new Date(data[data.length - 1].date);
        const isSingleWeek = (lastDate - firstDate) / (1000 * 60 * 60 * 24) <= 6;  // Difference in days is 7 or less
        
        setIsSingleMonth(isSingleMonth);
        setIsSingleWeek(isSingleWeek);
      }


    }
    );
  }, [portfolio, rows]);


  const get_chartDataDefault = () => {




    // Prepare the data for the chart
    const chartDataDefault = {
      // labels: data.map(item => new Date(item.date).toLocaleString('default', { month: 'short' })), // Month names
      // labels: data.map(item => {
      //   const date = new Date(item.date);
      //   if (isSingleWeek) {
      //     return date.toLocaleString('default', { weekday: 'short' });  // Show day names if data is within a single week
      //   } else if (isSingleMonth) {
      //     // return date.toLocaleString('default'); 
      //     return `Week ${Math.ceil(date.getDate() / 7)}`;  // Show weeks if data is within a single month
      //   } else {
      //     return date.toLocaleString('default', { month: 'short' });  // Show month names otherwise
      //   }
      // }),

      labels: data.map((item, index) => {
        const date = new Date(item.date);
      
        if (isSingleWeek) {
          // Show day names if data is within a single week
          return date.toLocaleDateString(userLanguage, { weekday: 'short' });
        } else if (isSingleMonth) {
          // Show one label per week if data is within 30 days
          const currentWeek = Math.ceil(date.getDate() / 7);
          const previousWeek = index > 0 ? Math.ceil(new Date(data[index - 1].date).getDate() / 7) : null;
          // Only return the label for the first day of each new week
          return currentWeek !== previousWeek ? `Week ${currentWeek}` : '';
        } else {
          // Show month names if data spans multiple months
          const currentMonth = date.toLocaleString(userLanguage, { month: 'short' });
          const previousMonth = index > 0 ? new Date(data[index - 1].date).toLocaleString(userLanguage, { month: 'short' }) : null;
          // Only return the label for the first day of each new month
          return currentMonth !== previousMonth ? currentMonth : '';
        }
      }),
      datasets: [
        // {
        //   label: 'Market Value %',
        //   data: data.map(item => item.total_market_value_change_percent),
        //   // data: data.map(item => item.total_market_value),
        //   borderColor: '#525f7f',
        //   backgroundColor: '#525f7f',
        //   fill: false,
        //   tension: 0.01,
        //   pointRadius: 0, // Reduce the size of the data points
        // },
        {
          label: 'Market Value',
          // data: data.map(item => item.total_market_value_change_percent),
          data: data.map(item => item.total_market_value),
          borderColor: '#525f7f',
          backgroundColor: '#525f7f',
          fill: false,
          tension: 0.1,
          pointRadius: 0, // Reduce the size of the data points
        },
      ],
    };
  return chartDataDefault;
  }

    useEffect(() => {
      if (type == "market_value") {
        // chartDataDefault.datasets[0].label = 'Market Value';
        // chartDataDefault.datasets[0].data = data.map(item => item.total_market_value);

        // setChartDataLocal(chartDataDefault);
        setChartDataLocal(get_chartDataDefault);
      }
      else {
        // setChartDataLocal(chartDataDefault);
        setChartDataLocal(get_chartDataDefault);

      }
    }, [data, aspectRatio, isSingleMonth, isSingleWeek]);

  const get_chartOptionsDefault = () => {
    const chartOptionsDefault = {
      scales: {
        x: {
          title: {
            display: false,
            text: 'Month',
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            minRotation: 0,
            maxRotation: 0,

          
            // This will only display unique month names on the x-axis
            // callback: function(value, index, values) {
            //   // Show only the first instance of each month
            //   const currentMonth = new Date(data[index].date).toLocaleString('default', { month: 'short' });
            //   const previousMonth = index > 0 ? new Date(data[index - 1].date).toLocaleString('default', { month: 'short' }) : null;
            //   return currentMonth !== previousMonth ? currentMonth : '';
            // },
            callback: function(value, index, values) {
              const currentDate = new Date(data[index].date);
              if (isSingleWeek) {
                // Display day names if within a single week
                return currentDate.toLocaleString(userLanguage, { weekday: 'short' });
                // return currentDate.toLocaleDateString(userLanguage, { day: 'numeric', month: 'numeric', year: 'numeric' })
              } else if (isSingleMonth) {
                // Display week numbers if within a single month
                // return currentDate.toLocaleString(userLanguage);
                // return `Week ${Math.ceil(currentDate.getDate() / 7)}`;
  
  
                // const currentWeek = `Week ${Math.ceil(currentDate.getDate() / 7)}`;
                // const previousWeek = index > 0 ? `Week ${Math.ceil(new Date(data[index - 1].date).getDate() / 7)}` : null;
                // // return currentWeek !== previousWeek ? currentWeek : '';
                // return currentWeek !== previousWeek ? currentDate.toLocaleDateString(userLanguage, { day: 'numeric', month: 'numeric' }) : '';



                const currentWeek = Math.ceil(currentDate.getDate() / 7);
                const previousWeek = index > 0 ? Math.ceil(new Date(data[index - 1].date).getDate() / 7) : null;
            
                // return currentWeek !== previousWeek ? currentDate.toLocaleDateString(userLanguage, { day: 'numeric', month: 'numeric' }) : '';
                // return index == 0 ? currentDate.toLocaleDateString(userLanguage, { day: 'numeric', month: 'numeric' }) : '';
                return (index === 0 || index % 7 === 0) ? currentDate.toLocaleDateString(userLanguage, { day: 'numeric', month: 'numeric' }) : '';

              } else {
                // Display unique month names if data spans multiple months
                const currentMonth = currentDate.toLocaleString(userLanguage, { month: 'short' });
                const previousMonth = index > 0 ? new Date(data[index - 1].date).toLocaleString(userLanguage, { month: 'short' }) : null;
                return (currentMonth !== previousMonth) ? currentMonth : '';

                
                              }
            },
            autoSkip: false,
          },
        },
        y: {
          grid: {
            display: true,
            color: (context) => {
              if (context.tick.value === 0) {
                return "#FDCD3F";
              }
              return 'rgba(0, 0, 0, 0.1)'; // default grid line color
            },
            borderDash: (context) => {
              if (context.tick.value === 0) {
                return [5, 5]; // dotted line
              }
              return [];
            },
            drawBorder: false,
          },
          ticks: {
            // Add '%' to each y-axis tick label
            callback: function(value) {
              // return `${value}`;
              return value.toFixed(2); // Ensure two decimal places
            },
          },
          title: {
            display: false,
            text: 'Market Value (EUR)',
          },
        },
      },
      responsive: true,
      aspectRatio: aspectRatio, // Set the aspect ratio to 4:3
      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false, // Hide the legend
        },
      },
    };

    return chartOptionsDefault;
  }

    useEffect(() => {
      if (type == "market_value") {
        // chartOptionsDefault.scales.y.ticks.callback = function(value) {
        //   // return `${value}`;
        //   return value.toFixed(2); // Ensure two decimal places

        // }
        // setChartOptionsLocal(chartOptionsDefault);
        setChartOptionsLocal(get_chartOptionsDefault);

      }
      else {
        // setChartOptionsLocal(chartOptionsDefault);
        setChartOptionsLocal(get_chartOptionsDefault);

      }


    }, [data, aspectRatio, isSingleMonth, isSingleWeek]);



  return (
    // <div >
 
    <div style={{ height: '100%', width: '100%' }}>   {/* <h2>Market Value Over Time</h2> */}
      {/* <Line data={chartDataLocal? chartDataLocal:  chartDataDefault} options={chartOptionsLocal ? chartOptionsLocal: chartOptionsDefault} /> */}
      {

      chartDataLocal && <Line data={chartDataLocal? chartDataLocal:  null} options={chartOptionsLocal ? chartOptionsLocal: null} />
      }
    </div>
  );
};

export default MarketValueChart;