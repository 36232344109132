


// import React from 'react';
// import PropTypes from 'prop-types';
// import { Col as BootstrapCol } from "reactstrap";
// import './Col.css';

// export const Col = ({ children, ...rest }) => {
  
//   return (
//     <BootstrapCol
//       {...rest}
//     >
//     {children}
//     </BootstrapCol>
//   )
// };

// Col.propTypes = {
  
// };


// Col.defaultProps = {
  
// };


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col as BootstrapCol } from "reactstrap";
import './Col.css';

export const Col = ({ children, xxl, ...rest }) => {
  // Map the custom `xxl` prop to a bootstrap-compatible class
  const xxxlClass = xxl ? `col-xxl-${xxl}` : null;

  return (
    <BootstrapCol
      {...rest}
      className={classNames(rest.className, xxxlClass)} // Combine custom and existing classes
    >
      {children}
    </BootstrapCol>
  );
};

Col.propTypes = {
  xxl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Allow numbers or strings
  children: PropTypes.node,
};

Col.defaultProps = {
  xxl: null,
};
