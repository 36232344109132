import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import FilesSelect from "components/Forms/FilesSelect/FilesSelect"
import UploadFile from "components/Forms/UploadFile/UploadFile"
import TransactionsFileHandling from "components/Forms/TransactionsFileHandling/TransactionsFileHandling"
import {  Checkbox } from "stories/forms";
import { httpGetMyUser, httpUpdateUser } from "services/users"
import { useAuthContext } from "contexts/AuthContext"




import {
  httpUploadFiles,
} from "services/files"


import { httpGetCompany, httpCreateCompany, httpUpdateCompany, httpDeleteCompany } from "services/companies"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

const defaultValues = {
  accept_ai_use: false,
}

const TransactionsListUploadFile = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      objectId,
      extraMargin,
      handleFileUpload,
      trxFiles,
      setTrxFiles,
      files,
      setFiles,
      myUser,
      analyze

   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDeleteCompany(objectId).then(() => {
        props.handleResolve()
        props.notify({ title:t("Company"), message:t("Poistettu")})
    })
  }
  
  return (
    <div className={extraMargin?"my-5":null}>
      <Row>
        <Col className="mb-3">
          <div className ="mb-3">
          <UploadFile   setFiles={setFiles} files={files} padding={30} />
          </div>
        </Col>
      </Row>

      
      {/* <Row>
        <Col className="mb-3">
          <Input 
            {...getFormikInputProps("business_identity_code")} 
            label={t("company_edit_identity_code", "Y-tunnus")}
            required
          />
        </Col>
      </Row>
       */}
      
      <Row>
      {myUser?.accept_ai_use === false &&
        <Col className="mb-3">
          <div>

        <Checkbox
             {...getFormikInputProps("accept_ai_use")}
             checked={values?.accept_ai_use}
             onChange={(e) => {setFieldValue("accept_ai_use",e.target.checked)}}
             value="agree_terms"
             required
             errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
             label={
               <>{t("register_step_1_read_terms",'Hyväksyn palvelun')} <Link className="underline-text mt-3" href="https://www.investime.fi/legal/" target="_blank">{t('käyttöehdot')}</Link> {t("ja")} <Link className="underline-text mt-3" href="https://www.investime.fi/legal/" target="_blank">{t("order_terms",'tilausehdot')}</Link></>
              }
              labelFirst={true}
              className="zIndexZero"
              />
              </div>
        {touched.accept_ai_use && <Typography className="text-danger">{errors["accept_ai_use"]}</Typography>}
        </Col>
      }
        <Col className="mb-3 text-right">

            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
        
              {!analyze?       t("analyze","Analysoi"):
                    <>
                      <div className="mx-5"></div>
                      <Spinner animation="border" size="xs" className="spinner-height-18 "/>
                    </>
                  }
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
          {/* {JSON.stringify(myUser?.accept_ai_use)} */}
        
    </div>
  );
}

const TransactionsListUploadFileFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        accept_ai_use: Yup.boolean()
        .oneOf([true], required) // This requires the field to be `true`
        .nullable(),

      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account,handleFileUpload, files, getMyUser, myUser } = props;
      console.log("account", account);
      let data = {
        accept_ai_use: values.accept_ai_use,
      };

      if (files?.length === 0) {
        setErrors({
          general: t("Please upload a file")
        })
        setSubmitting(false);
        return
      }

      if (myUser?.accept_ai_use === false){
      
        httpUpdateUser(myUser.id, data).then(res => {
          setSubmitting(false);
          if(res?.data?.id){
            handleFileUpload()
          }

          getMyUser()
          // props.notify({ title:t("User"), message:t("Updated")})
          
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else{
        handleFileUpload()
        props.handleResolve()
      }
      
      
      // if (values?.id) {
      //   httpUpdateCompany(values.id, data).then(res => {
      //     setSubmitting(false);
      //     props.notify({ title:t("Company"), message:t("Updated")})
      //       props.handleResolve()
          
      //   }, error => {
      //     setSubmitting(false);
      //     if (error?.data?.detail || error?.data?.general) {
      //       setErrors({
      //         general: error?.data?.detail,
      //       })
      //     }
      //     else {
      //       setErrors({
      //         general: t("Unknown error. Please try again later or contact support.")
      //       })
      //     }
      //   })
      // }
      // else {
      //   httpCreateCompany(data).then(res => {
      //     setSubmitting(false);
      //     props.notify({ title:t("Company"), message:t("Created")})
      //     props.handleResolve()
          
      //   }, error => {
      //     setSubmitting(false);
      //     if (error?.data?.detail) {
      //       setErrors({
      //         general: error?.data?.detail,
      //       })
      //     }
      //     else {
      //       setErrors({
      //         general: t("Unknown error. Please try again later or contact support.")
      //       })
      //     }
      //   })
      // }
      
      
    },
    displayName: "BasicForm"
  
})(TransactionsListUploadFile)
    
    
const TransactionsListUploadFileView = ({history, objectId, modalOpen,extraMargin,getPreTransactions,handleResolve, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const [files, setFiles] = useState([]);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const [trxFiles, setTrxFiles] = useState([]);
  const [analyze, setAnalyze] = useState(false);
  const { getMyUser, myUser } = useAuthContext()

  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetCompany(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        business_identity_code: res.business_identity_code,
        ...res?.data,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  const handleFileUpload = (transactions) => {
    if (!files?.length) return
    setAnalyze(true)

    for (let i = 0; i < files.length; i++) {
    
      const formData = new FormData();
      formData.append('file', files[i])
      // formData.append('files', files);
      formData.append('account', account?.id);
      
      formData.append('portfolio', portfolio.id);
      formData.append('ai_analyze', true);
      // setLoadingSendExcel(true)
      httpUploadFiles(formData).then(res => {
        if (res?.data){
          getPreTransactions()
          notify({ title:t("File"), message:t("analyzed")})
          
          console.log(res?.data)
        }
 
        
        // setLoadingSendExcel(false)
        
      }, error => {
        // setErrorText("Tapahtumien tuonti epäonnistui")
        if(error?.data?.message) {
          // setErrorMessage(t(error?.data?.message))
        }
        if(error?.data?.general) {
          //replace : with . to avoid rendering issues
          const general = error?.data?.general?.toString()
          const formattedError = general.replace(/:/g, '.')
        // setErrorMessage(t(formattedError))
      }
      
    }).finally(() => {
      setFiles([])
      handleResolve()
      setAnalyze(false)
      // setLoadingSendExcel(false)
      
      // setLoadingImport(false)
    })
    
  }
  
  };
  
  
  useEffect(() => {
    setPreSetValues({
      ...defaultValues,
      accept_ai_use: myUser?.accept_ai_use,
    });
  }, [objectId, modalOpen, myUser])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <TransactionsListUploadFileFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      extraMargin={extraMargin}
      handleFileUpload={handleFileUpload}
      trxFiles={trxFiles}
      setTrxFiles={setTrxFiles}
      files={files}
      setFiles={setFiles}
      myUser={myUser}
      analyze={analyze}
      {...rest} 
    />
  )
  
}

export default TransactionsListUploadFileView;
