import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

// Stories
import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

// App
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import { httpGetStockData } from "services/stocks"
import { getDate } from "services/datetime"
import { thousandFormat } from "services/helpers";
import { roundScaleDecimals } from "services/helpers";

const PublicStockRatesList = ({filters, setFilters, setObjectsToChart, objectsToChart}) => {
  
  const { t } = useTranslation();
  const stocks = useStoreState((state) => state.stocks?.objects);
  const [loading, setLoading] = useState(false)
  const [objects, setObjects] = useState()
  const [count, setCount] = useState()
  
  const findStockObject = (id) => {
    const filteredObjects = stocks && stocks.filter(o => o.id === id)
    if (filteredObjects && filteredObjects.length > 0) {
      return filteredObjects[0]
    }
    return null
  }

  useEffect(() => {
    setLoading(true)

  }, [filters.stock_market])

  
  const getStockRates = () => {
    const stockObject = findStockObject(filters?.stock)
    let nasdaq_id = undefined; // "ACG1V";
    if (stockObject) {
      nasdaq_id = stockObject?.nasdaq_id
    }
    else{
      nasdaq_id = filters?.passed_nasdaq_id
    }
    const stock_date = undefined; // moment("2021-12-28").toISOString().split('T')[0] +" 00:00"; // "2021-12-27";
    let params = {
      ...filters,
      nasdaq_id: nasdaq_id,
      stock_date__gte: "1970-01-01"
    }
    
    // Stock date
    // params["stock_date__gte"] = moment(values?.date_transaction).toISOString().split('T')[0] +" 23:59"; 
    // params["stock_date__lte"] = moment(values?.date_transaction).add("days",1).toISOString().split('T')[0] +" 23:59"; 
    
    if (filters?.stock && filters.stock_name && params.nasdaq_id) {
      setLoading(true)
      httpGetStockData(params).then(res => {
        setObjects(res?.data?.results)
        setCount(res?.data?.count)
        // setObjectsToChart(res?.data?.results)
        setLoading(false)
      })
    } 
    else{
      setObjects([])
      setCount(0)
    }

  }
  
  useEffect(() => {
    getStockRates()
  }, [filters])
  
  
  const headers = [
    { label: t("public_stock_rates_list_name", "Name"), key: "name"},
    { label: t("public_stock_rates_list_stock_date", "Date"), key: "stock_date"},
    { label: t("public_stock_rates_list_opening_price", "Aloitushinta"), key: "updated_opening_price"},
    { label: t("public_stock_rates_list_highest_price", "Ylin hinta"), key: "updated_highest_price"},
    { label: t("public_stock_rates_list_lowest_price", "Alin hinta"), key: "updated_lowest_price"},
    { label: t("public_stock_rates_list_ending_price", "Päätös hinta"), key: "updated_ending_price"},
    { label: t("public_stock_rates_list_exhange_pcs", "Vaihto kpl"), key: "updated_exhange_pcs"},

  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const stockObject = findStockObject(filters?.stock)
    const name = stockObject?.name
    return objects && objects.map((row, index) => {
      return {
        ...row,
        name: name,
        stock_date: getDate(row?.stock_date),
        updated_opening_price: row?.updated_opening_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_opening_price, 2)), 
          className: "text-right"
        },
        updated_highest_price: row?.updated_highest_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_highest_price, 2)),
          className: "text-right"
        },
        updated_lowest_price: row?.updated_lowest_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_lowest_price, 2)),
          className: "text-right"
        },
        updated_ending_price: row?.updated_ending_price && {
          content: thousandFormat(roundScaleDecimals(row?.updated_ending_price, 4)),
          className: "text-right"
        },
        updated_exhange_pcs: row?.updated_exhange_pcs && {
          content: thousandFormat(roundScaleDecimals(row?.updated_exhange_pcs, 2)),
          className: "text-right"
        },
        
      }
    })
  }, [objects])
  
  return (
    <>
 
        <TableAuto
          showRowNumber={true}
          showId={true}
          checkboxes={false}
          headers={headers}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={count}
          setFilters={setFilters}
        />
     
    </>
  );
}

export default PublicStockRatesList;
