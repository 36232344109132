import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { Input, SelectMulti, Toggle, Radio, DatePicker, Checkbox } from "stories/forms";

import { Button, Typography, Spinner, Link, Popover  } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";

import { useTranslation } from 'react-i18next';
import {
    httpUploadFiles,
    httpUpdateFile,
    httpDeleteFile,
    httpGetFiles,
  } from "services/files"
import { set } from 'date-fns';

import FilesSelect from "components/Forms/FilesSelect/FilesSelect"
import FileDownloadButton from "components/Buttons/FileDownloadButton/FileDownloadButton"
import { fi } from 'date-fns/locale';



const TransactionsFileHandling = ({ files, setFiles, transaction, setFieldValue, values }) => {
    const { t } = useTranslation();
    const [link_files, setLinkFiles] = useState(false);
    const [all_files, setAllFiles] = useState([]);
    const [fileSelectValue, setFileSelectValue] = useState(null);

    // useEffect(() => {
    //     setFileSelectValue([]);
    // }, [])  

    // useEffect(() => {
    //     setFieldValue("connect_files", fileSelectValue)
    // }, [fileSelectValue])

    const removeFile = (id) => {
        const confirmed = window.confirm(t("Oletko varma että haluat poistaa tiedoston pysyvästi?"));

        if (!confirmed) {
            return; // Do nothing if user cancels
        }
        httpDeleteFile(id).then((response) => {
            console.log(response);
            setFiles(files.filter(file => file.id !== id));
        }).catch((error) => {
            console.log(error);
        });
    }

    const connect_files = () => {
        let preValues = values?.connect_files;
        if (!preValues) {
            preValues = [];
            // preValues.append(fileSelectValue)

        }

        setFieldValue("connect_files", [...preValues,fileSelectValue]);
        setFileSelectValue(null);
        //append link_files to fileSelectValue

    }


    const removeConnectedFile = (file) => {
        setFieldValue("connect_files", values.connect_files.filter(f => f.value !== file.value));
        // setFileSelectValue(fileSelectValue.filter(f => f.label !== file.label));
    }

    const disconnectFile = (id, trx_id) => {
        //show alert before disconnecting
            // Show alert before disconnecting
        const confirmed = window.confirm(t("Oletko varma että haluat katkaista linkityksen tiedostoon?"));

        if (!confirmed) {
            return; // Do nothing if user cancels
        }

        const data ={
            remove_transaction: trx_id
        }
        httpUpdateFile(id, data).then((response) => {
            console.log(response);
            setFiles(files.filter(file => file.id !== id));
        }).catch((error) => {
            console.log(error);
        });
    }

    // if (!files || !files.length) {
    //     return <></>;
    // }

    return (
        //loop through files
        <div>
            {/* <Row className="mb-2">
    
                <Col  className="my-3">
                <div style={{position: 'absolute', zIndex: "0 !important"}}>
                <Checkbox 
                id="link_files"
                name="link_files"
                checked={link_files}
                onChange={(e) => {setLinkFiles(e.target.checked)}}
                value="agree_terms"
                required
                // errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                label={<>{t("link_files",'Yhdistä olemassa oleva tiedosto')}</>}
                labelFirst={true}
                className="zIndexZero"
                />
                </div>

                </Col>
            </Row> */}
            {link_files || true && (
            <Row className="mb-3">
                <Col className="mb-0">
                    {/* <label className="mb-1 pr-1 header-bold">{t("purchase_connected_files", "Linkitä")}</label> */}
                    <FilesSelect label={t("files_select_label", "Tiedostot")} name="files" isMulti={false} setFileSelectValue={setFileSelectValue} fileSelectValue={fileSelectValue} />
                    {/* <TransactionsFileHandling setFiles={setTrxFiles} files={trxFiles} transaction={values} /> */}
                    {/* {JSON.stringify(fileSelectValue)}
                    {JSON.stringify(values?.connect_files)} */}
                    {fileSelectValue != null && ( 
                        <div className="d-flex">

                        <Button className="mt-3 ml-auto" variant="secondary"  size="sm" onClick={() => connect_files()}>{t("Yhdistä")}</Button>
                        </div>
                        )
                    }
                </Col>
            </Row>
            )  
            }

            {values?.connect_files && values?.connect_files.length > 0 && (
                <ul className="pl-0 mb-3 mt-1">
                {values?.connect_files.map((file, index) => (
                    <li key={index} className="ml-0 mb-2" style={{listStyleType: 'none' }}>
                        <Input
                            name="index"
                            label=""
                            min={0}
                            addonTypeClass="append"
                            
                            inputText={
                                <Button className="text-white font-weight-light rounded-circle custom-xs-button-input  b-0" style={{position:"relative", zIndex: 0}} variant="lighter" onClick={() => removeConnectedFile(file)} >
                                <i className="fas fa-times-circle text-danger bg-white fa-xl"  />
                                </Button>
                        
                            }
                            value={file.label}
                        
                        />
                    </li>
                ))}
                </ul>
            )

            }
            <div>
            {files.length > 0 &&
                <label className="mb-1 pr-1 header-bold">{t("purchase_connected_files", "Linkitetyt tiedostot")}</label>
            }
            {files.map(file => (
                <div key={file.id} className="mb-2"  >
                    {/* <Typography variant="h6">{file?.filename}</Typography> */}
                    {/* <Button onClick={() => disconnectFile(file.id)}>{t('disconnect')}</Button> */}
                    {/* <Button onClick={() => removeFile(file.id, transaction?.id)}>{t('remove')}</Button> */}
                    {/* <FileDownloadButton fileId={file?.id} /> */}

                    <Input
                            name="index"
                            label=""
                            min={0}
                            addonTypeClass="append"
                            
                            inputText={
                                <>
                                <Button id={`disconnect-${file?.id}`} className="text-white font-weight-light rounded-circle custom-xs-button-input  b-0 mr-0" style={{position:"relative", zIndex: 0}}  variant="lighter" onClick={() => disconnectFile(file.id, transaction?.id)} >
                                <i className="fas fa-ban text-primary bg-white fa-xl"  />
                                </Button>
                                <Popover
                                    placement="top"
                                    target={`disconnect-${file?.id}`}
                                    >       
                                    <Typography className="text-dark mb-1" variant="medium">
                                        {t('Katkaise linkitys')}
                                    </Typography>  
                                </Popover>
                                <Button id={`remove-${file?.id}`} className="text-white font-weight-light rounded-circle custom-xs-button-input  b-0"  style={{position:"relative", zIndex: 0}}  variant="lighter" onClick={() => removeFile(file.id)} >
                                <i className="fas fa-trash-alt text-primary bg-white fa-xl"  />
                                </Button>
                                <Popover
                                    placement="top"
                                    target={`remove-${file?.id}`}
                                    >       
                                    <Typography className="text-dark mb-1" variant="medium">
                                        {t('Poista tiedosto')}
                                    </Typography>  
                                </Popover>
                                <FileDownloadButton  showPreview fileId={file?.id} fileName={file?.filename} polygons={[values.quantity_polygon, values.price_polygon]} />
                               
                                </>
                        
                            }
                            value={file?.filename}
                        
                        />

                </div>
            ))}
            </div>

           
        </div>
    )

}

export default TransactionsFileHandling;