import React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

import { httpGetAllSecurities2 } from "services/private_investment_funds";
import {
    FormGroup,
  } from "reactstrap";

import { Typography } from "stories/components"

const customStyles = {
    option: (provided, state) => {
      // Check if the option is selected and apply custom styles
      return {
        ...provided,
        color: state.isSelected ? 'white' : (state.data.status ? 'green' : 'black'), // Green if status is true
        backgroundColor: state.isSelected ? 'blue' : 'white', // Change background color for selected state
      };
    },
    singleValue: (provided, state) => {
      // Apply styles to the single selected value
      return {
        ...provided,
        color: state.data?.status ? 'green' : 'black', // Green if status is true
      };
    },
  };

// let now_timestamp = null

const SecuritySelect = ({ onChange, label, style, focused, required, portfolio,disabled,value, delisted=false, sale=false, className=null  }) => {
  // Function to fetch securities based on the search string
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const in_portfolio = (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}> 
      <Typography className="text-primary ml-2 my-0" variant="h4" >(</Typography>
      <i className="fas fa-star fa-xs text-secondary  mb-2" style={{ fontSize: '8px' }}></i>
      <Typography className="text-primary my-0" variant="h4" >{t("all_security_select_in_portfolio","salkussa")})</Typography>


    </span>
  )

  const loadOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) {
      return []
    }

    try {
      // Call the API to get securities
      const response = await httpGetAllSecurities2({ search_string: inputValue, portfolio: portfolio, delisted: delisted, sale: sale });
      const securities = response?.data?.all_securities || []; // Handle undefined data

      const options = [];
      let lastRelatedModelType = "start";

      // Transform data to the format required by react-select
      securities.forEach(security => {
        // Check if the related model type has changed
        if (security.type !== lastRelatedModelType) {
          // If it's not the first type, you can add a header or separator option here
        //   lastRelatedModelType = security.related_model_type; // Update the last related model type
          lastRelatedModelType = security.type; // Update the last related model type
          if (lastRelatedModelType !== null) {
            options.push({ 
              value: null, 
            //   label: t(`rel_type ${lastRelatedModelType}`),
              label: (
                // <div className="custom-border-color" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                //   <Typography className="text-primary" variant="h3" >{t(`${security.type}`,lastRelatedModelType)}</Typography>
                  
                // </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
            
                  <Typography className="text-primary mr-2 my-0" variant="h3" >{t(`${security.type}`,lastRelatedModelType)}{security?.status==true? in_portfolio:null}</Typography>
                  <div className="bg-primary" style={{ flexGrow: 1, height: '1px', backgroundColor: '#ccc' }}></div>
                </div>
              ),
              
              isDisabled: true // Make it disabled
            });
          }
        }

        options.push({
          value: security.id, // Set the value to the security ID
          label: security.status ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className="fas fa-star text-secondary" style={{ color: 'green', marginRight: '8px' }}></i>
                {security.name}
              </div>
              <span className="ml-2 text-dark" style={{ marginLeft: 'auto'}}>
              {security.ticker_symbol}{security?.stock_market ? ":" + security?.stock_market : ""}
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <span>{security.name}</span>
              <span className="ml-2 text-dark">
                {security.ticker_symbol}{security?.stock_market ? ":" + security?.stock_market : ""}
              </span>
            </div>
          ),
          related_model_type: security.related_model_type,
          related_model_id: security.id,
          status: security.status,
          type: security.type,
          name: security.name,
          ticker_symbol: security.ticker_symbol,
          stock_market: security.stock_market,
          currency_label: security.currency_label,
          original_type: security?.original_type
        });
      });

      return options; // Return the constructed options list

    //   // Transform data to the format required by react-select
    //   return securities.map(security => ({
    //     value: security.id, // Set the value to the security ID
    //     // label: `${security.name} (${security.ticker_symbol})`, // Set the label to show name and ticker symbol
    //     // label: security.status ? (
    //     //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //     //       <i className="fas fa-star text-secondary mr-1" style={{ color: 'green', marginRight: '8px' }}> </i>
    //     //       {`${security.name} (${security.ticker_symbol})`}
    //     //     </div>
    //     //   ) : (
    //     //     `${security.name} (${security.ticker_symbol})`
    //     //   ),

    //     label: security.status ? (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    //           <div style={{ display: 'flex', alignItems: 'center' }}>
    //             <i className="fas fa-star text-secondary" style={{ color: 'green', marginRight: '8px' }}></i>
    //             {security.name }
    //           </div>
    //           <span className="ml-2" style={{ marginLeft: 'auto', color: 'gray' }}> {/* Optional styling for the ticker */}
    //             {security.ticker_symbol}
    //           </span>
    //         </div>
    //       ) : (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    //           <span>{security.name }</span>
    //           <span className="ml-2" style={{ color: 'gray'}}>{security.ticker_symbol}{security?.stock_market?":"+security?.stock_market:""}</span> {/* Optional styling for the ticker */}

    //         </div>
    //       ),
    //     related_model_type: security.related_model_type,
    //     related_model_id: security.id,
    //     status: security.status,
    //     type: security.type,
    //     name: security.name,
    //     ticker_symbol: security.ticker_symbol,
    //     stock_market: security.stock_market,

    //   }));
    } catch (error) {
      console.error('Error fetching securities:', error);
      return []; // Return an empty array on error
    }
  };

    // Custom function to format the selected option
  // Custom function to format the selected option
  const formatOptionLabel = (option) => {
    const { name, ticker_symbol, stock_market, status } = option;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {status ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className="fas fa-star text-secondary" style={{ color: 'green', marginRight: '8px' }}></i>
            <span>{name}</span>
          </div>
        ) : (
          <span>{name}</span>
        )}
        <span style={{ color: 'gray' }} >
          {ticker_symbol}{stock_market ? ":" + stock_market : ""}
        </span>
      </div>
    );
  };

  return (
    <FormGroup
        className={`${focused} ? "focused" : ""} my-0` } style={{style}} 
    >
        {label && (
            <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
        )}
        <AsyncSelect
            cacheOptions
            loadOptions={loadOptions} // Use the function to load options
            // defaultOptions // Show default options or initial loading options
            placeholder={t("all_security_select_2", "Hae...")} // Placeholder text
            onChange={onChange} // Call the onChange prop when an option is selected
            isDisabled={disabled} // Disable the select if the disabled prop is
            value = {value}
            onInputChange={(value) => setInputValue(value)}
            menuIsOpen={inputValue.length >= 2} 
            defaultOptions={false}
            className={className}
            // styles={customStyles} // Apply custom styles
            // formatOptionLabel={formatOptionLabel} // Apply custom formatting to the selected option
        />
    </FormGroup>
  );
};

export default SecuritySelect;
