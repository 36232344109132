import React, { useEffect, useState, useCallback } from "react";

import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

import { Col, Container, Row } from "stories/layout"

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';
import IncomeReportSummary from "views/app/IncomeReportSummary/IncomeReportSummary"



// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";

const Diary = (props) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();

  //Export button from IncomeReportSummary
  const [exportButton, setExportButton] = useState(null);

  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("income_report_title", "Tuottoraportti"),
          url: "/dashboard",  
        },
    ]
    //if location state include crumbLinks return them
    if (props.location?.state && props.location?.state?.crumbLinks) {
      const crumbLinks = [...props.location.state.crumbLinks]
      crumbLinks.push({name: t("income_report_title","Tuottoraportti"), url: "dashboard/reports/profit-report", optional: true})
      // props.location.state.crumbLinks.push({name: t("income_report_title","Tuottoraportti"), url: "/dashboard", optional: true})
      return crumbLinks
    }
    //else return default links
    return crumbLinks
  }

  const title = t("Tervetuloa palveluun, ") + (myUser?.first_name? myUser?.first_name : myUser?.username);

  
  return (
    <>
    <Row className="d-flex  flex-wrap  ">
    <Col>
      <PageHeader title={t("income_report_title","Tuottoraportti")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
    </Col>
    <Col md="6" xs="12" className="d-flex flex-column justify-content-end  mr-xs-auto align-self-end mb-3 " style={{minWidth: "50px"}}>
      <div className="ml-auto">
         {exportButton}
      </div>
    </Col>
    </Row>
    <IncomeReportSummary setExportButton={setExportButton} / > 
{/*
    <Row>
        <Col className="mb-3">
          <img src={graph01} />
        </Col>
        <Col className="mb-3">
          <img src={graph02} />
        </Col>
  </Row>*/}
    </>
  );
}

export default Diary;