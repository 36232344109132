import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as BootstrapModal,
} from "reactstrap";
import './Modal.css';

import { IconButton } from "stories/components";

export const Modal = ({ 
  title="", 
  isOpen=false, 
  toggleModal, 
  closeModal, 
  buttons,
  showHeader=true,
  className,
  classNameBody,
  size,
  backdrop="static", // When backdrop is set to static, the modal will not close when clicking outside it.
  fade=true,
  children, ...rest 

}) => {
  
  const handleClose = () => {
    if (closeModal) {
      closeModal()
    }
    else if (toggleModal) {
      toggleModal()
    }
  }
  
  return (
    <BootstrapModal
      className={`modal-dialog-centered ${className ? className : ""} ${size ? size : ""}`}
      isOpen={isOpen}
      toggle={toggleModal}
      backdrop={backdrop}
      fade={false}
      {...rest}
    >
      {showHeader && 
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
        {title}
        </h5>
        <IconButton aria-label="Close" size="lg" onClick={handleClose} iconName="times" data-dismiss="modal" />
      </div>
      }
      <div className={`modal-body pt-0 ${classNameBody ? classNameBody : ""} `}>
      {children}
      </div>
      
      { buttons && (
        <div className="modal-footer">
          <div style={{width:"100%"}} className="d-flex justify-content-between">
            {buttons}
          </div>
        </div>
      )}
    </BootstrapModal>
  )
};

Modal.propTypes = {
  buttons: PropTypes.array,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['modal-sm','modal-lg','modal-xl','modal-xxl' ]),
  title: PropTypes.string,
  toggleModal: PropTypes.func,
};

