import React from "react";
import { useTranslation } from 'react-i18next';

// Stories
import { PageHeader, PageContainer } from "components/Page"

// import { Col, Container, Row,  } from "stories/layout"
import { Row, Col, Container, Card,CardBody, CardHeader,   } from "stories/layout";

import {Link, Breadcrumb, BreadcrumbItem, Button, BreadcrumbsDynamic, Popover} from 'stories/components';
import { useAuthContext } from "contexts/AuthContext";
import { Markup } from 'interweave';



// App
import graph01 from "assets/images/graph_01.jpg"
import graph02 from "assets/images/graph_02.jpg"
import { Typography } from "stories/components";

const Report = (props) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();


  //get crumb links from location state or return default links
  const getCrumbLinks = () => {
    const crumbLinks = [
      {
        name: t("Raportit"),
          url: "/dashboard/reports",  
        },
    ]
    //if location state include crumbLinks return them
    // if (props.location?.state && props.location?.state?.crumbLinks) {
    //   props.location.state.crumbLinks.push({name: t("Raportit"), url: "/dashboard"})
    //   return props.location.state.crumbLinks
    // }
    //else return default links
    return crumbLinks
  }

  const title = t("Tervetuloa palveluun, ") + (myUser?.first_name? myUser?.first_name : myUser?.username);

  
  return (
    <>
      <PageHeader title={t("Raportit")} />
      <BreadcrumbsDynamic links={getCrumbLinks()} />
      <PageContainer>
        <Row className="mb-5">
          <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-0">
            <Link

              className="font-weight-bold text-dark mb-2"
              to={{
                pathname: "/dashboard/reports/portfolio-summary",
                state: { crumbLinks: getCrumbLinks() },
              }}
            >
              <Card className="shadow-sm-strong h-100 m-2 ">
                  <CardBody className="d-flex flex-column align-items-center text-center">
                    <i className="fas fa-briefcase text-primary fa-2x mb-3"></i>
                      <Typography variant="h3">{t("reports_portfolio_summary", "Salkkuraportti")}</Typography>
                    <Typography className="text-muted">
                      {t("reports_portfolio_summary_description", "Sisälttää salkun taseen, tuottojen ja kulujen yhteenvedon.")}
                    </Typography>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-sm-4 mt-md-0">
              <Link

                className="font-weight-bold text-dark mb-2"
                to={{
                  pathname: "/dashboard/reports/profit-report",
                  state: { crumbLinks: getCrumbLinks() },
                }}
              >
                <Card className="shadow-sm-strong h-100 m-2 ">
                  <CardBody className="d-flex flex-column align-items-center text-center">
                    <i className="fas fa-euro-sign text-primary fa-2x mb-3"></i>
                      <Typography variant="h3">{t("profit_report","Tuottoraportti")}</Typography>
                    <Typography className="text-muted">
                      {t("profit_report_description","Sisältää keskeiset tiedot sijoitusten arvonmuutoksista, mahdollisista voitoista ja tappioista.")}
                    </Typography>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-sm-4 mt-xl-0">

              <Link
                className="font-weight-bold text-dark mb-2"
                to={{
                  pathname: "/dashboard/reports/tax-report",
                  state: { crumbLinks: getCrumbLinks() },
                }}
              >

                <Card className="shadow-sm-strong h-100 m-2">
                  <CardBody className="d-flex flex-column align-items-center text-center">
                    <i className="fas fa-file-invoice-dollar text-primary fa-2x mb-3"></i>
                      <Typography variant="h3">{t("reports_tax_reports", "Veroraportti 9A")} </Typography>
                    <Typography className="text-muted">
                      {t("reports_tax_reports_description", "Yksityisille sijoittajille verotukseen liittyvä vuosiraportti.")}
                    </Typography>
                  </CardBody>
                </Card>
              </Link>

         
            </Col>
            <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-sm-4 mt-xxl-0">
              <Link
                className="font-weight-bold text-dark mb-2"
                // to={{
                //   pathname: "/dashboard/reports/risk-analysis-report",
                //   state: { crumbLinks: getCrumbLinks() },
                // }}
              >
                <Card id="risk_analysis" className="shadow-sm-strong h-100 m-2 "  style={{ opacity: 0.6,}} >
                  <CardBody className="d-flex flex-column align-items-center text-center">
                    <i className="fas fa-briefcase text-primary fa-2x mb-3"></i>
                  
                      <Typography variant="h3">{t("reports_risk_analysis","Riskianalyysiraportti")}</Typography>
                    <Typography className="text-muted">
                      {t("reports_risk_analysis_description","Arvioi sijoitusten tai salkun riskejä, kuten markkina- ja likviditeettiriskejä.")}
                    </Typography>
                  </CardBody>
                </Card>
              </Link>
              <Popover
                placement="top"
                target="risk_analysis"
              >       
                <Typography className="text-warning mb-1" variant="medium">
                    {t("reports_asset_manager_coming", "Tulossa")}
                </Typography>  
              </Popover>
            </Col>

            <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-4"  >
              <Link
                className="font-weight-bold text-dark mb-2"
                // to={{
                //   pathname: "/dashboard/reports/asset-manager-report",
                //   state: { crumbLinks: getCrumbLinks() },
                // }}
              >
                <Card  id="asset_manager" className="shadow-sm-strong h-100 m-2 "  style={{ opacity: 0.6,  }}>
                    <CardBody className="d-flex flex-column align-items-center text-center">
                      <i className="fas fa-briefcase text-primary fa-2x mb-3"></i>
                    
                        <Typography variant="h3">
                          {t("reports_asset_manager", "Varainhoitajan mukaan")}
                        </Typography>
                      <Typography className="text-muted">
                        {t("reports_asset_manager_description", "Analysoi sijoitusmarkkinoiden ja eri omaisuusluokkien kehitystä.")}
                      </Typography>
                    </CardBody>
                </Card>
              </Link>
     
              <Popover
                // placement="up"
                target="asset_manager"
              >       
                <Typography className="text-warning mb-1" variant="medium">
                    {t("reports_asset_manager_coming", "Tulossa")}
                </Typography>  
              </Popover>
            </Col>
            {/* <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-4">
              <Card className="shadow-sm-strong h-100 m-2 ">
                <CardBody className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-briefcase text-primary fa-2x mb-3"></i>
                  <Link

                    className="font-weight-bold text-dark mb-2"
                    to={{
                      pathname: "/dashboard/reports/cost-report",
                      state: { crumbLinks: getCrumbLinks() },
                    }}
                  >
                    <Typography variant="h3">Kustannusraportti</Typography>
                  </Link>
                  <Typography className="text-muted">
                    Sisältää keskeiset tiedot sijoitusten arvonmuutoksista, mahdollisista voitoista ja tappioista.
                  </Typography>
                </CardBody>
              </Card>
          </Col> */}
          {/* <Col sm={12} md={6}  lg={6} xl={4}  xxl={3} className="mt-4">
          <Card className="shadow-sm-strong h-100 m-2 ">
                <CardBody className="d-flex flex-column align-items-center text-center">
                  <i className="fas fa-briefcase text-primary fa-2x mb-3"></i>
                  <Link

                    className="font-weight-bold text-dark mb-2"
                    to={{
                      pathname: "/dashboard/reports/cashflow-report",
                      state: { crumbLinks: getCrumbLinks() },
                    }}
                  >
                    <Typography variant="h3">Kassavirtaraportti</Typography>
                  </Link>
                  <Typography className="text-muted">
                    Seuraa rahavirtoja, näyttää mistä varat tulevat ja miten niitä käytetään.
                  </Typography>
                </CardBody>
              </Card>
          </Col> */}
        </Row>
      </PageContainer>
    </>
  );
}

export default Report;