import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';


import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"
import { useAppContext } from "contexts/AppContext"
import { round, roundScaleDecimals } from "services/helpers"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import PreTransactionEdit from "views/app/PreTransactionEdit/PreTransactionEdit"
import SplitTransactionEdit from "views/app/SplitTransactionEdit/SplitTransactionEdit"
import SplitTransactionGroupCreate from "views/app/SplitTransactionGroupCreate/SplitTransactionGroupCreate"
import { date } from "yup/lib/locale";

const UnmadePreTransactions = (props) => {
  
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const account = useStoreState((state) => state.account?.account);

  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()
  
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);

  const loading = useStoreState((state) => state.portfolio?.loading);
  const count = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);

  const preTransactions = props?.preTransactions || []


  
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const headers = [

      // // { label: t("unmade_split_transactions_list_batch", "Erä id"), key: "batch"},
      // { label: t("unmade_split_transactions_list_split_id", "Split id"), key: "split_id"},
      // { label: t("unmade_split_transactions_list_instrument_name", "Instrumentti"), key: "instrument_name"},
      // // { label: t("unmade_split_transactions_list_batch_purchase_date", "Hankintapäivä"), key: "batch_purchase_date"},
      // // { label: t("unmade_split_transactions_list_batch_balance", "erän koko ennen splittiä"), key: "batch_balance"},
      // { label: t("unmade_split_transactions_list_split_dividend", "Kerroin"), key: "dividend"},
      // { label: t("unmade_split_transactions_list_split_date_effective", "SPLIT Päivä"), key: "date_effective"},
      // // { label: t("unmade_split_transactions_list_portfolio", "Salkku"), key: "portfolio"},




      // { label: t("unmade_pre_transactions_portfolio", "Salkku"), key: "portfolio"},
      { label: t("unmade_pre_transactions_date_transaction", "Tapahtumapäivä"), key: "date_transaction"},
      { label: t("unmade_pre_transactions_import_name", "Tuonti nimi"), key: "import_name"},
      { label: t("unmade_pre_transactions_ticker_symbol", "Tuonti symbol"), key: "import_ticker_symbol"},
      { label: t("unmade_pre_transactions_quantity", "Määrä"), key: "quantity"},
      { label: t("unmade_pre_transactions_price", "Hinta"), key: "price"},
      { label: t("unmade_pre_transactions_price_total", "Hinta yhteensä"), key: "price_total"},
      { label: t("unmade_pre_transactions_broker_name", "Välittäjä"), key: "broker_name"},
      { label: t("unmade_pre_transactions_reference_code", "Viitekoodi"), key: "reference_code"},
      // { label: t("unmade_pre_transactions_commission_basic_amount", "Perusprovisio"), key: "commission_basic_amount"},
      // { label: t("unmade_pre_transactions_commission_percentage", "Prosenttipalkkio"), key: "commission_percentage"},
      // { label: t("unmade_pre_transactions_commission_total", "Provisio yhteensä"), key: "commission_total"},
      // { label: t("unmade_pre_transactions_currency_exchange_rate", "Valuuttakurssi"), key: "currency_exchange_rate"},
      // { label: t("unmade_pre_transactions_currency_label", "Valuutta"), key: "currency_label"},
      // { label: t("unmade_pre_transactions_date_settlement", "Maksupäivä"), key: "date_settlement"},
      // { label: t("unmade_pre_transactions_id", "Id"), key: "id"},
      // { label: t("unmade_pre_transactions_notes", "Muistiinpanot"), key: "notes"},
      // { label: t("unmade_pre_transactions_stock_market", "Pörssi"), key: "stock_market"},
      // { label: t("unmade_pre_transactions_type", "Tyyppi"), key: "type"},
      // { label: t("unmade_pre_transactions_user", "Käyttäjä"), key: "user"},
      // { label: t("unmade_pre_transactions_value_total", "Arvo yhteensä"), key: "value_total"},
      // { label: t("unmade_pre_transactions_related_model_type", "Liittyvä malli tyyppi"), key: "related_model_type"},
      // { label: t("unmade_pre_transactions_related_model_id", "Liittyvä malli id"), key: "related_model_id"},
      // { label: t("unmade_pre_transactions_stock_split", "Osakesplitti"), key: "stock_split"},
      // { label: t("unmade_pre_transactions_dividend_instance", "Osinko"), key: "dividend_instance"},
      // { label: t("unmade_pre_transactions_import_ticker", "Tuonti symbol"), key: "import_ticke_symbol"},
      // { label: t("unmade_pre_transactions_isin", "Isin"), key: "isin"},
      // { label: t("unmade_pre_transactions_name", "Nimi"), key: "name"},
      // { label: t("unmade_pre_transactions_related_model_type", "Liittyvä malli tyyppi"), key: "related_model_type"},
      // { label: t("unmade_pre_transactions_related_model_id", "Liittyvä malli id"), key: "related_model_id"},
      // { label: t("unmade_pre_transactions_user", "Käyttäjä"), key: "user"}, 
      // { label: t("unmade_pre_transactions_created", "Luotu"), key: "created"},
      // { label: t("unmade_pre_transactions_stock_split", "Osakesplitti"), key: "stock_split"},
      // { label: t("unmade_pre_transactions_dividend_instance", "Osinko"), key: "dividend_instance"},
      // { label: t("unmade_pre_transactions_processed", "Käsitelty"), key: "processed"},
      // { label: t("unmade_pre_transactions_file", "Tiedosto"), key: "file"},
      // { label: t("unmade_pre_transactions_notes", "Muistiinpanot"), key: "notes"},




    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];

  const passPortfolio  = (id) => {
    // get query params id
      console.log("id", id)
      const filtered = portfolios.filter(o => o.id == id)
      if (filtered && filtered.length > 0) {
        setPortfolio(filtered[0])
      }
  }


  useEffect(() => {
    // getUnmadeSplitTransactions()
    }, [])
  
  
  
  
  const getTableRows = useCallback(() => {

    let data = preTransactions;



    return preTransactions && preTransactions.map((row, index) => {
      let batch_balance = Number(row[0]?.batch?.batch_balance)
      return {
        ...row,
        // split_id: row[0]?.splits?.id,
        // instrument_name: <Typography variant="h5" className="text-dark">{row[0]?.batch?.related_model?.name}</Typography>,
        date_transaction: moment(row?.date_transaction).format('DD.MM.YYYY'),
        // dividend: row[0]?.splits?.dividend,
        actions: (
          <div className="d-flex justify-content-end">

          <Button name="process_split_transactions_button" className="btn-sm " color="danger" onClick={() => openModalEdit(row)}>{t("unmade_split_transactions_list_process_button","Käsittele")}</Button>
          </div>
        ),
      }
    })
    

  }, [preTransactions])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditBatch, setModalEditBatch] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (batch) => {
    setModalEditOpen(true)
    setModalEditBatch(batch);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    // setModalEditBatch(null);
    // getUnmadeSplitTransactions()
    props.getTransactions()
    props.getPreTransactions()
    // props.setParentFilters({
    //   ...filters,
    //   update: props.parentfilters.update? true:false
    // })

  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditBatch(null);
  }

  return (
    <>
       
        <div className="d-flex justify-content-start mb-2">
   
        {/* <i className="fas fa-exclamation-triangle text-danger fa-2x mr-3" /> */}
        <PageHeader title={t("unmade_split_transactions_list_title","Käsittele saapuneet kirjaukset")} />
        </div>
      
        <div className="d-flex justify-content-between mb-2">
          <div className="py-2">
           {/*
           <Button color="secondary" onClick={() => getUnmadeSplitTransactions()}>{t("Päivitä")}</Button>
           */}  
          </div>
          
          <div className="p-2">
            
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Typography variant="h5" className="text-warning">{t("unmade_split_transactions_list_process_order","Vanhimmat ensin")}</Typography>
        </div>

        
        <TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={false}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        setFilters={setFilters}
        name={"preTransactions"}
        />
        {/* {JSON.stringify(modalEditOpen)} */}
        
        <Modal
          title={t("portfolios_list_portfolio_title","Salkku")}
          showHeader={false}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
          size="modal-xxl"
        >
          <PreTransactionEdit
            object={modalEditBatch}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>

    </>
  );
}

export default UnmadePreTransactions;
