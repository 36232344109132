


// import React, { useState, useEffect } from 'react';  
import { PDFDocument, rgb } from 'pdf-lib';  
import { httpDownloadFile } from "services/files";  
import React, { useState, useEffect, useRef, useCallback } from 'react';  
import { Worker, Viewer } from '@react-pdf-viewer/core';  
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import '@react-pdf-viewer/core/lib/styles/index.css';  
import '@react-pdf-viewer/default-layout/lib/styles/index.css';  
// import { httpDownloadFile } from "services/files";  
import { version as pdfjsVersion } from 'pdfjs-dist/package.json';  
// import { useCallback } from 'react/cjs/react.production.min';
  
const ModifyPdf = ({ pdfBytes,url, polygons, initialPage=1 }) => {  
  const [modifiedPdfUrl, setModifiedPdfUrl] = useState(null);  


  

  
  useEffect(() => {  
    const modifyPdf = async () => {  
        const uint8Array = new Uint8Array(pdfBytes);  
        console.log(pdfBytes);
        const pdfDoc = await PDFDocument.load(uint8Array);  
    // //   const pdfDoc = await PDFDocument.load(pdfBytes);  
      const pages = pdfDoc.getPages();  
      const firstPage = pages[0];  
    //   const page = pages[pageNumber - 1]; // Page numbers are 0-indexed  
    
      const inchToPoints = 72; // Conversion factor  
      const colors = [  
        'rgba(253, 205, 63, 0.9)',   // Yellow  
        'rgba(22, 22, 64, 0.9)',     // Dark Blue  
        'rgba(255, 111, 97, 0.9)',   // Coral  
        'rgba(107, 91, 149, 0.9)',   // Purple  
        'rgba(136, 176, 75, 0.9)',   // Olive Green  
        'rgba(247, 202, 201, 0.9)',  // Light Pink  
        'rgba(146, 168, 209, 0.9)',  // Light Blue  
        'rgba(149, 82, 81, 0.9)',    // Brownish Red  
        'rgba(181, 101, 167, 0.9)',  // Lavender  
        'rgba(255, 160, 122, 0.9)',  // Light Salmon  
        'rgba(72, 201, 176, 0.9)',   // Mint Green  
        'rgba(255, 195, 0, 0.9)'     // Bright Yellow  
      ];  
      polygons.forEach(({ polygon, page, hover }, index) => {  
        if (!polygon || !page || page < 1 || page > pages?.length) {  
          return;  
        }
        const pageIndex = page - 1; // Convert to 0-based index  
        const pdfPage = pages[pageIndex];  
        const pageHeight = pdfPage.getHeight();  

        const colorIndex = index % colors.length;  
        const [r, g, b, a] = colors[colorIndex]  
          .match(/\d+(\.\d+)?/g)  
          .map(Number); 

        polygon.forEach((point, i) => {  
          const start = point.map(coord => coord * inchToPoints);  
          const end = polygon[(i + 1) % polygon.length].map(coord => coord * inchToPoints);  

          // Flip y-coordinates  
          start[1] = pageHeight - start[1];  
          end[1] = pageHeight - end[1];  

          pdfPage.drawLine({  
            start: { x: start[0], y: start[1] },  
            end: { x: end[0], y: end[1] },  
            // color: rgb(1, 0, 0),  
            color: rgb(r / 255, g / 255, b / 255), 
            opacity: a,
            thickness: 1,  
          });  
        });  
      });  
  


      const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });  
      console.log(pdfDataUri);
      setModifiedPdfUrl(pdfDataUri);  
    };  
  
    modifyPdf();  
  }, [pdfBytes]);  



    const styles = {  
        viewerContainer: {  
        position: 'relative',  
        width: '100%',  
        //   height: '600px',  
        height: '900px',  
        overflow: 'auto',  
        },  
    
    };  

    const get_viewer = useCallback(() => {
    return(
        <Viewer fileUrl={modifiedPdfUrl? modifiedPdfUrl: url} />  
    )
  }, [modifiedPdfUrl]);



  const get_pdf = useCallback(() => {
        if (!modifiedPdfUrl) {
            return <div>Loading PDF...</div>;  // Add loading indicator if the URL is not ready
        }
        return <Viewer fileUrl={modifiedPdfUrl} initialPage={initialPage} />;
    }, [modifiedPdfUrl, initialPage]);
  
  return (  
    <div style={styles.viewerContainer } >  
      {url ? (  
        // <>
        // {JSON.stringify(initialPage)}
        // {get_pdf1()}
        // </>
        // // <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>  
            
          {modifiedPdfUrl? (
      
            <Viewer fileUrl={modifiedPdfUrl}    initialPage={initialPage} />
            ):(
                <>
                dd
            {/* <Viewer fileUrl={url} /> */}
                </>
            )
            }
        </Worker>  
      ) : (  
        <></>
        // <div>Loading PDF...</div>  
      )}  
      <div ></div>  
    </div>  
  );  
};  
  
const MainComponent = ({ fileId, fileName, polygons, initialPage }) => {  
  const [pdfBytes, setPdfBytes] = useState(null);  
  const [fileUrl, setFileUrl] = useState(null);  

  console.log(polygons, 999);
    
  const fileType = 'application/pdf';  
  const downloadFile = async () => {  
    if (!fileId || !fileName) {  
      console.error("File ID or name is missing");  
      return;  
    }  
  
    try {  
      const response = await httpDownloadFile(fileId);  
      if (response) {  
        const blob = new Blob([response.data], { type: 'application/pdf' });  
        const arrayBuffer = await blob.arrayBuffer();  
        setPdfBytes(arrayBuffer);  
        // setPdfBytes(response.data); // Use the ArrayBuffer directly  
        const file = new Blob([response.data], { type: fileType });  
        const fileURL = window.URL.createObjectURL(file);  
        console.log(fileURL);
        setFileUrl(fileURL);  
      }  
    } catch (error) {  
      console.error("There was an error downloading the file!", error);  
    }  
  };  
  
  useEffect(() => {  
    downloadFile();  
  }, []);  

  const get_pdf = useCallback(() => {
    return(
        <ModifyPdf pdfBytes={pdfBytes} url={fileUrl} polygons={polygons} initialPage={initialPage} />  
    )
  }, [polygons]);
  
  return (  
    <div>  
      {pdfBytes ? (  
        // <>
        // {get_pdf()}
        // </>
        <ModifyPdf pdfBytes={pdfBytes} url={fileUrl} polygons={polygons}  initialPage={initialPage} />  
      ) : (  
        <div>Loading PDF...</div>  
      )}  
    </div>  
  );  
};  
  
export default MainComponent;  

//------------------------------------WORK _______--------------------------------------------------------
// import React, { useState, useEffect, useRef } from 'react';  
// import { Worker, Viewer } from '@react-pdf-viewer/core';  
// import '@react-pdf-viewer/core/lib/styles/index.css';  
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';  
// import { httpDownloadFile } from "services/files";  
// import { version as pdfjsVersion } from 'pdfjs-dist/package.json';  
  
// const MainComponent = ({ fileId, fileName }) => {  
//   const [fileUrl, setFileUrl] = useState(null);  
//   const viewerRef = useRef(null);  
  
//   const downloadFile = async () => {  
//     if (!fileId || !fileName) {  
//       console.error("File ID or name is missing");  
//       return;  
//     }  
  
//     const fileType = 'application/pdf';  
//     try {  
//       const response = await httpDownloadFile(fileId);  
//       if (response) {  
//         const file = new Blob([response.data], { type: fileType });  
//         const fileURL = window.URL.createObjectURL(file);  
//         setFileUrl(fileURL);  
//       }  
//     } catch (error) {  
//       console.error("There was an error downloading the file!", error);  
//     }  
//   };  
  
//   useEffect(() => {  
//     downloadFile();  
//   }, []);  
  
//   const styles = {  
//     viewerContainer: {  
//       position: 'relative',  
//       width: '100%',  
//       height: '600px',  
//       overflow: 'auto',  
//     },  
//     polygon: {  
//       position: 'absolute',  
//       top: '150px', // Adjust based on the document content  
//       left: '100px', // Adjust based on the document content  
//       width: '100px',  
//       height: '100px',  
//       backgroundColor: 'rgba(255, 0, 0, 0.5)',  
//       clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',  
//     },  
//   };  
  
//   return (  
//     <div style={styles.viewerContainer } ref={viewerRef}>  
//       {fileUrl ? (  
//         // <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
//         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>  
            
//           <Viewer fileUrl={fileUrl} />  
//         </Worker>  
//       ) : (  
//         <div>Loading PDF...</div>  
//       )}  
//       <div style={styles.polygon}></div>  
//     </div>  
//   );  
// };  
  
// export default MainComponent;  
//------------------------------------WORK _______--------------------------------------------------------



// import React, { useState, useEffect } from 'react';
// import * as pdfjsLib from 'pdfjs-dist';
// import { httpDownloadFile } from "services/files";
// import { Document, Page } from 'react-pdf';



// import React, { useState, useEffect } from 'react';
// import { pdfjs } from 'react-pdf';
// import * as pdfjsLib from 'pdfjs-dist';
// import { httpDownloadFile } from "services/files";
// import { Document, Page } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

// const MainComponent = ({ fileId, fileName, fileModel = "File" }) => {
//     const [fileUrl, setFileUrl] = useState(null);

//     const downloadFile = async (isMounted) => {
//         if (!fileId || !fileName) {
//             console.error("File ID or name is missing");
//             return;
//         }

//         const fileType = 'application/pdf';
//         try {
//             const response = await httpDownloadFile(fileId);
//             if (response && isMounted) {
//                 const file = new Blob([response.data], { type: fileType });
//                 const fileURL = window.URL.createObjectURL(file);
//                 setFileUrl(fileURL);
//             }
//         } catch (error) {
//             console.error("There was an error downloading the file!", error);
//         }
//     };

//     useEffect(() => {
//         let isMounted = true;
//         downloadFile(isMounted);

//         return () => {
//             isMounted = false;
//         };
//     }, []);

//     // const DownloadedFileViewer1 = ({ fileUrl }) => (
//     //     <div>
//     //         {fileUrl ? (
//     //             <Document file={fileUrl} onLoadError={(error) => console.error(error)}>
//     //                 <Page pageNumber={1} />
//     //             </Document>
//     //         ) : (
//     //             <p>Loading PDF...</p>
//     //         )}
//     //     </div>
//     // );

//     const styles = {  
//         container: {  
//           display: 'flex',  
//           justifyContent: 'center',  
//           alignItems: 'center',  
//           padding: '20px',  
//           border: '1px solid #ddd',  
//           borderRadius: '8px',  
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
//           backgroundColor: '#f9f9f9',  
//         },  
//         iframe: {  
//           border: 'none',  
//           borderRadius: '4px',  
//           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',  
//           width: '100%',  
//           height: '600px',  
//         },  
//         loading: {  
//           fontSize: '16px',  
//           color: '#555',  
//           display: 'flex',  
//           alignItems: 'center',  
//           justifyContent: 'center',  
//           height: '100px',  
//         },  
//       };   
//     const DownloadedFileViewer = ({ fileUrl }) => {
//                 return (
//                   <div >
//                     {fileUrl ? (
//                       <iframe
//                         src={fileUrl}
//                         width="100%"
//                         height="100%"
//                         // height="600px"
//                         title="PDF Viewer"
//                         style={styles.iframe}  
//                         frameBorder="0"
//                       />
//                     ) : (
//                       <p>Loading PDF...</p>
//                     )}
//                   </div>
//                 );
//               };

//     return (
//         <>
//             <DownloadedFileViewer fileUrl={fileUrl} />
//         </>
//     );
// };

// export default MainComponent;


// const MainComponent = ({fileId, fileName, fileModel="File"}) => {
//     const [fileUrl, setFileUrl] = useState(null);
  
//     const downloadFile = () => {
//       if (!fileId || !fileName) {
//         console.error("File ID or name is missing");
//         return;
//       }
  
//       const fileType = 'application/pdf';
  
//       if (fileModel === "File") {
//         httpDownloadFile(fileId)
//           .then((response) => {
//             if (response) {
//               let file = new Blob([response.data], { type: fileType });
//               let fileURL = window.URL.createObjectURL(file);
//               setFileUrl(fileURL);
//             } else {
//               throw new Error("Network response was not ok.");
//             }
//           })
//           .catch((error) => {
//             console.error("There was an error downloading the file!", error);
//           });
//       }
//     };
  
//     useEffect(() => {
//       downloadFile();
//     }, []);

//     const DownloadedFileViewer = ({ fileUrl }) => {
//         return (
//           <div>
//             {fileUrl ? (
//               <Document file={fileUrl} onLoadError={(error) => console.error(error, 333)}>
//                 <Page pageNumber={1} />
//               </Document>
//             ) : (
//               <p>Loading PDF...</p>
//             )}
//           </div>
//         );
//       };


//       const DownloadedFileViewer1 = ({ fileUrl }) => {
//         return (
//           <div>
//             {fileUrl ? (
//               <iframe
//                 src={fileUrl}
//                 width="100%"
//                 height="600px"
//                 title="PDF Viewer"
//                 frameBorder="0"
//               />
//             ) : (
//               <p>Loading PDF...</p>
//             )}
//           </div>
//         );
//       };
  
//     return (
//         <>
//     <DownloadedFileViewer fileUrl={fileUrl} />
//         </>
    
//     )
//     ;
//   };
  
//   export default MainComponent;








// import React, { useState } from 'react';
// import * as pdfjsLib from 'pdfjs-dist';
// import { httpDownloadFile } from "services/files";


// const PDFViewerWithDownload = ({ fileId, fileName, fileModel="File" }) => {
//   const [fileUrl, setFileUrl] = useState(null); // State to store Blob URL for the PDF

//   // Function to download the file and get the Blob URL
//   const downloadFile = () => {
//     if (!fileId || !fileName) {
//       return;
//     }

//     const params = { responseType: 'blob' }; // Keep responseType as 'blob' to handle Blob response
//     let fileExtension = fileName.split('.').pop().toLowerCase();
//     let fileTypePre = 'application/pdf';

//     if (fileModel === 'File') {
//       httpDownloadFile(fileId, params)
//         .then((response) => {
//           if (response && response.data) {
//             // Log the response to see what you're getting
//             console.log('File response:', response);

//             // Check if the response data is a Blob
//             if (response.data instanceof Blob) {
//               const reader = new FileReader();
//               reader.onloadend = function () {
//                 const arrayBuffer = reader.result;
//                 if (arrayBuffer instanceof ArrayBuffer) {
//                   // Create a file URL for displaying the PDF
//                   let fileURL = URL.createObjectURL(new Blob([arrayBuffer], { type: fileTypePre }));
//                   setFileUrl(fileURL); // Update the file URL to trigger rendering
//                 }
//               };
//               reader.readAsArrayBuffer(response.data); // Read the Blob as ArrayBuffer
//             }
//           }
//         })
//         .catch((error) => console.error('Error downloading file:', error));
//     }
//   };

//   // PDFViewer function to render the PDF from Blob URL
//   const renderPDF = async (pdf, canvasRef) => {
//     if (!pdf || !canvasRef.current) return;

//     // Get the first page (you can modify this to render multiple pages if needed)
//     const page = await pdf.getPage(1);  // Rendering only the first page here
//     const viewport = page.getViewport({ scale: 1.0 });  // Adjust scale if needed

//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     const renderContext = {
//       canvasContext: context,
//       viewport: viewport,
//     };

//     // Render the page onto the canvas
//     await page.render(renderContext).promise;
//   };

//   // PDFViewer component that takes fileUrl as prop
//   const PDFViewer = ({ fileUrl }) => {
//     const [pdfData, setPdfData] = useState(null); // Stores the loaded PDF data
//     const canvasRef = React.useRef(null); // Ref to the canvas element where the PDF will be rendered

//     React.useEffect(() => {
//       if (!fileUrl) return; // Exit if no file URL is provided

//       const loadPDF = async () => {
//         try {
//           // Fetch the PDF file as a Blob
//           const pdfBlob = await fetch(fileUrl).then((res) => res.blob());
//           const pdfArrayBuffer = await pdfBlob.arrayBuffer();  // Convert Blob to ArrayBuffer

//           // Load the PDF document using pdf.js
//           const pdf = await pdfjsLib.getDocument(pdfArrayBuffer).promise;
//           setPdfData(pdf);  // Save the PDF data to state
//           renderPDF(pdf, canvasRef);    // Render the first page
//         } catch (error) {
//           console.error('Error loading PDF:', error);
//         }
//       };

//       loadPDF();
//     }, [fileUrl]);  // Re-run this effect if the fileUrl changes

//     return (
//       <div>
//         <h2>PDF Viewer</h2>
//         <canvas ref={canvasRef}></canvas>
//         {!pdfData && <p>Loading PDF...</p>} {/* Display loading text until PDF is loaded */}
//       </div>
//     );
//   };

//   return (
//     <div>
//       <h1>Download and View PDF</h1>
      
//       {/* Button to trigger file download */}
//       <button onClick={downloadFile}>Download PDF</button>

//       {/* Render the PDFViewer component, passing fileUrl to it */}
//       {fileUrl && <PDFViewer fileUrl={fileUrl} />}
//     </div>
//   );
// };

// export default PDFViewerWithDownload;



// import React, { useEffect, useRef, useState } from 'react';
// import { httpDownloadFile } from "services/files";
// import { PDFDocument } from 'pdf-lib';
// import * as pdfjsLib from 'pdfjs-dist';

// const PdfWithPolygons = ({ fileId, fileName, polygons, fileModel = "File" }) => {
//   const canvasRef = useRef(null);
//   const [pdfDoc, setPdfDoc] = useState(null);
//   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

//   const downloadFile = () => {
//     if (!fileId || !fileName) {
//       return;
//     }

//     const params = { responseType: 'blob' }; // Keep responseType as 'blob' to handle Blob response
//     if (fileModel === "File") {
//       httpDownloadFile(fileId, params)
//         .then((response) => {
//           if (response && response.data) {
//             console.log('File response:', response);

//             // Check if the response data is a Blob
//             if (response.data instanceof Blob) {
//               const reader = new FileReader();
//               reader.onloadend = function () {
//                 const arrayBuffer = reader.result;
//                 if (arrayBuffer instanceof ArrayBuffer) {
//                   PDFDocument.load(arrayBuffer).then((pdf) => {
//                     setPdfDoc(pdf);
//                     renderPdf(arrayBuffer);
//                   }).catch((error) => {
//                     console.error('Error loading PDF:', error);
//                   });
//                 } else {
//                   console.error('Failed to convert Blob to ArrayBuffer');
//                 }
//               };
//               reader.readAsArrayBuffer(response.data); // Convert Blob to ArrayBuffer
//             } else {
//               console.error('Expected Blob, but got:', response.data);
//             }
//           } else {
//             throw new Error('Network response was not ok.');
//           }
//         })
//         .catch((error) => {
//           console.error('There was an error!', error);
//         });
//     }
//   };

//   const renderPdf = (arrayBuffer) => {
//     // Use pdf.js to render the PDF
//     const loadingTask = pdfjsLib.getDocument(arrayBuffer);
//     loadingTask.promise
//       .then((pdf) => {
//         const page = pdf.getPage(1); // Get the first page

//         page.then((page) => {
//           const canvas = canvasRef.current;
//           const context = canvas.getContext('2d');
//           const viewport = page.getViewport({ scale: 1 });

//           // Set the canvas size to match the PDF page size
//           canvas.width = viewport.width;
//           canvas.height = viewport.height;

//           // Render the page onto the canvas
//           page.render({
//             canvasContext: context,
//             viewport: viewport,
//           }).promise.then(() => {
//             setImageSize({ width: viewport.width, height: viewport.height });
//           });
//         });
//       })
//       .catch((error) => {
//         console.error('Error loading PDF with pdf.js:', error);
//       });
//   };

//   useEffect(() => {
//     downloadFile();
//   }, [fileId, fileName]);

//   useEffect(() => {
//     // After PDF is loaded, render polygons
//     if (pdfDoc && canvasRef.current) {
//       const canvas = canvasRef.current;
//       const context = canvas.getContext('2d');

//       const { width, height } = imageSize;
//       const colors = [
//         'rgba(253, 205, 63, 0.8)',   // Yellow  
//         'rgba(22, 22, 64, 0.8)',     // Dark Blue  
//         'rgba(255, 111, 97, 0.8)',   // Coral  
//         'rgba(107, 91, 149, 0.8)',   // Purple  
//         'rgba(136, 176, 75, 0.8)',   // Olive Green  
//         'rgba(247, 202, 201, 0.8)',  // Light Pink  
//         'rgba(146, 168, 209, 0.8)',  // Light Blue  
//         'rgba(149, 82, 81, 0.8)',    // Brownish Red  
//         'rgba(181, 101, 167, 0.8)',  // Lavender  
//         'rgba(255, 160, 122, 0.8)',  // Light Salmon  
//         'rgba(72, 201, 176, 0.8)',   // Mint Green  
//         'rgba(255, 195, 0, 0.8)'     // Bright Yellow  
//       ];

//       polygons.forEach((polygon, index) => {
//         if (!Array.isArray(polygon)) {
//           console.error('Each polygon should be an array of points');
//           return;
//         }

//         context.beginPath();
//         polygon.forEach((point, pointIndex) => {
//           if (!Array.isArray(point) || point.length !== 2) {
//             console.error('Each point should be an array with two numbers');
//             return;
//           }

//           // Scale the absolute coordinates to the current page size
//           const [x, y] = point;
//           const scaledX = (x / width) * canvas.width;
//           const scaledY = (y / height) * canvas.height;
//           if (pointIndex === 0) {
//             context.moveTo(scaledX, scaledY);
//           } else {
//             context.lineTo(scaledX, scaledY);
//           }
//         });
//         context.closePath();
//         context.strokeStyle = colors[index % colors.length];
//         context.lineWidth = 2;
//         context.stroke();
//       });
//     }
//   }, [polygons, imageSize]);

//   return (
//     <div style={{ position: 'relative' }}>
//       <canvas
//         ref={canvasRef}
//         style={{ position: 'absolute', top: 0, left: 0 }}
//       />
//     </div>
//   );
// };

// export default PdfWithPolygons;


// import React, { useEffect, useRef, useState } from 'react';  
// import { httpDownloadFile } from "services/files";  
// import { PDFDocument, rgb } from 'pdf-lib';  

// const PdfWithPolygons = ({ fileId, fileName, polygons, fileModel = "File" }) => {  
//   const canvasRef = useRef(null);  
//   const [fileUrl, setFileUrl] = useState(null);  
//   const [pdfDoc, setPdfDoc] = useState(null);  
//   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });  

//   // Function to download and load the PDF


//   const downloadFile = () => {  
//     if (!fileId || !fileName) {  
//       return;  
//     }  
  
//     const params = { responseType: 'blob' };  // Keep responseType as 'blob' to handle Blob response
//     let fileExtension = fileName.split('.').pop().toLowerCase();  
//     let fileTypePre = 'application/pdf';  
  
//     if (fileModel === "File") {  
//       httpDownloadFile(fileId, params)  
//         .then((response) => {  
//           if (response && response.data) {  
//             // Log the response to see what you're getting
//             console.log('File response:', response);
  
//             // Check if the response data is a Blob
//             if (response.data instanceof Blob) {
//               const reader = new FileReader();
//               reader.onloadend = function () {
//                 const arrayBuffer = reader.result;
//                 if (arrayBuffer instanceof ArrayBuffer) {
//                   // Create a file URL for displaying the image (optional)
//                   let fileURL = URL.createObjectURL(new Blob([arrayBuffer], { type: fileTypePre }));
//                   setFileUrl(fileURL);
  
//                   // Now load the PDF using pdf-lib
//                   PDFDocument.load(arrayBuffer).then((pdf) => {
//                     setPdfDoc(pdf);
//                   }).catch((error) => {
//                     console.error('Error loading PDF:', error);
//                   });
//                 } else {
//                   console.error('Failed to convert Blob to ArrayBuffer');
//                 }
//               };
//               reader.readAsArrayBuffer(response.data);  // Convert Blob to ArrayBuffer
//             } else {
//               console.error('Expected Blob, but got:', response.data);
//             }
//           } else {  
//             throw new Error('Network response was not ok.');  
//           }  
//         })  
//         .catch((error) => {  
//           console.error('There was an error!', error);  
//         });  
//     }  
//   };

//   useEffect(() => {  
//     downloadFile();  
//   }, [fileId, fileName]);  

//   // Render the PDF with polygons
//   useEffect(() => {  
//     const renderPdf = async () => {
//       if (!pdfDoc) return;

//       const page = pdfDoc.getPages()[0]; // Get the first page

//       const canvas = canvasRef.current;
//       const context = canvas.getContext('2d');

//       // Get the page size and scale
//       const { width, height } = page.getSize();
//       const scale = canvas.width / width;
//       setImageSize({ width: width * scale, height: height * scale });

//       context.clearRect(0, 0, canvas.width, canvas.height);

//       // Draw polygons
//       const colors = [  
//         'rgba(253, 205, 63, 0.8)',   // Yellow  
//         'rgba(22, 22, 64, 0.8)',     // Dark Blue  
//         'rgba(255, 111, 97, 0.8)',   // Coral  
//         'rgba(107, 91, 149, 0.8)',   // Purple  
//         'rgba(136, 176, 75, 0.8)',   // Olive Green  
//         'rgba(247, 202, 201, 0.8)',  // Light Pink  
//         'rgba(146, 168, 209, 0.8)',  // Light Blue  
//         'rgba(149, 82, 81, 0.8)',    // Brownish Red  
//         'rgba(181, 101, 167, 0.8)',  // Lavender  
//         'rgba(255, 160, 122, 0.8)',  // Light Salmon  
//         'rgba(72, 201, 176, 0.8)',   // Mint Green  
//         'rgba(255, 195, 0, 0.8)'     // Bright Yellow  
//       ];

//       polygons.forEach((polygon, index) => {
//         if (!Array.isArray(polygon)) {
//           console.error('Each polygon should be an array of points');
//           return;
//         }

//         context.beginPath();
//         polygon.forEach((point, pointIndex) => {
//           if (!Array.isArray(point) || point.length !== 2) {
//             console.error('Each point should be an array with two numbers');
//             return;
//           }

//           // Scale the absolute coordinates to the current page size
//           const [x, y] = point;
//           const scaledX = (x / width) * canvas.width;
//           const scaledY = (y / height) * canvas.height;
//           if (pointIndex === 0) {
//             context.moveTo(scaledX, scaledY);
//           } else {
//             context.lineTo(scaledX, scaledY);
//           }
//         });
//         context.closePath();
//         context.strokeStyle = colors[index % colors.length];
//         context.lineWidth = 2;
//         context.stroke();
//       });
//     };

//     renderPdf();
//   }, [polygons, fileUrl, pdfDoc]);  

//   return (  
//     <div style={{ position: 'relative' }}>
//         df
//         {JSON.stringify(canvasRef)}
//       <canvas  
//         ref={canvasRef}  
//         style={{ position: 'absolute', top: 0, left: 0 }}  
//         width={imageSize.width}  
//         height={imageSize.height}  
//       />
//     </div>  
//   );  
// };  

// export default PdfWithPolygons;  




// import React, { useRef, useEffect, useState } from "react";
// import { Document, Page } from "react-pdf";
// import { httpDownloadFile } from "services/files";

// const PdfWithPolygons = ({ fileId, fileName, polygons, fileModel = "File" }) => {
//   const [fileUrl, setFileUrl] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });

//   const canvasRef = useRef(null);

//   // Function to download the file
//   const downloadFile = () => {
//     if (!fileId || !fileName) {
//       console.error("File ID or name is missing");
//       return;
//     }

//     // const params = { responseType: "blob" };
//     const fileExtension = fileName.split(".").pop().toLowerCase();
//     // const fileType = fileExtension === "pdf" ? "application/pdf" : "application/octet-stream";
//     const fileType = 'application/pdf'

//     if (fileModel === "File") {
//       httpDownloadFile(fileId)
//         .then((response) => {
//           if (response) {
//             // const file = new Blob([response.data], { type: fileType });
//             // const fileURL = URL.createObjectURL(file);
//             // setFileUrl(fileURL);

//             let file = new Blob([response.data], {type: fileType});
//             let fileURL = window.URL.createObjectURL(file);

//             setFileUrl(fileURL);

//           } else {
//             throw new Error("Network response was not ok.");
//           }
//         })
//         .catch((error) => {
//           console.error("There was an error downloading the file!", error);
//         });
//     }
//   };

//   useEffect(() => {
//     downloadFile();
//   }, [fileId, fileName]);

//   // Handle successful load of the document
//   const handleLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   // Handle the page rendering to get dimensions
//   const handlePageRender = (page) => {
//     const { width, height } = page.getBoundingClientRect();
//     setPageDimensions({ width, height });
//   };

//   // Draw polygons on the canvas
//   useEffect(() => {
//     if (!canvasRef.current || !pageDimensions.width || !pageDimensions.height) return;

//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");
//     context.clearRect(0, 0, canvas.width, canvas.height);

//     const colors = [
//       "rgba(253, 205, 63, 0.8)", // Yellow
//       "rgba(22, 22, 64, 0.8)", // Dark Blue
//       "rgba(255, 111, 97, 0.8)", // Coral
//     ];

//     polygons.forEach((polygon, index) => {
//       if (!Array.isArray(polygon)) {
//         console.error("Each polygon should be an array of points");
//         return;
//       }

//       context.beginPath();
//       polygon.forEach(([x, y]) => {
//         const scaledX = (x / 100) * pageDimensions.width; // Scale points if provided in percentages
//         const scaledY = (y / 100) * pageDimensions.height;

//         context.lineTo(scaledX, scaledY);
//       });
//       context.closePath();
//       context.strokeStyle = colors[index % colors.length];
//       context.lineWidth = 2;
//       context.stroke();
//     });
//   }, [polygons, pageDimensions]);

//   return (
//     <div style={{ position: "relative" }}>
//       {fileUrl ? (
//         <>
//           <Document
//             file={fileUrl}
//             onLoadSuccess={handleLoadSuccess}
//           >
//             <Page
//               pageNumber={currentPage}
//               renderMode="svg" // Use SVG for better compatibility
//               onRenderSuccess={(page) => handlePageRender(page)}
//               style={{ display: "block", width: "100%" }}
//             />
//           </Document>
//           <canvas
//             ref={canvasRef}
//             style={{
//               position: "absolute",
//               top: 0,
//               left: 0,
//               pointerEvents: "none",
//             }}
//             width={pageDimensions.width}
//             height={pageDimensions.height}
//           />
//           {numPages > 1 && (
//             <div>
//               <button
//                 onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
//                 disabled={currentPage === 1}
//               >
//                 Previous
//               </button>
//               <button
//                 onClick={() => setCurrentPage((prev) => Math.min(prev + 1, numPages))}
//                 disabled={currentPage === numPages}
//               >
//                 Next
//               </button>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Loading PDF...</p>
//       )}
//     </div>
//   );
// };

// export default PdfWithPolygons;
